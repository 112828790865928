/* eslint-disable max-lines-per-function */
import React, { useRef, useState } from "react";
import SingleLevelTable from "../../S3/singleLevelTable";
import Loader from "../../../../../Common/Loader";
import ExcelDownload from "../../../../../Common/ExcelDownload";
import { ReactComponent as MoreIcon } from "../../../../../../Assets/icons/menu.svg";
import { ReactComponent as ExpandIcon } from "../../../../../../Assets/icons/maximize-arrow.svg";
import { handleKeyPress } from "../../EKS/common/helper";
import useDetectOutsideClick from "../../../../../../CustomHooks/useDetectOutsideClick";
import TableModal from "./TableModal";
import { downloadExcel, noop } from "../../../../../../Utils/commonUtils";
import Chart from "./FusionChart";
import ExpandedGraph from "./ExpandedGraph";
import Tabs from "../Tabs";
import Select from "./Select";
import { getSelectedValue } from "../../helper/utils";
import { getHeaderValues } from "../../cudosUtil";

function Graph(props) {
  const {
    graph: {
      heading,
      type,
      graphData,
      graphtableData,
      graphTableHeader,
      graphTableFooter = [],
      name,
      showMoreIcon = false,
      showGraphExpandIcon = false,
      width,
    },
    id,
    dataTransferHeading,
    wrapperClass = "",
    onExcelDownload = () => {},
    graphLoading = false,
    showSortingOnTotal = false,
    activeGranularity,
    configuration,
    updateConfig = noop,
    apiKey,
    headerComponent = {},
  } = props;
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showGraphModal, setShowGraphModal] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    getSelectedValue(configuration?.selectedTab, configuration?.tabArr)?.label
  );
  const [selectedOption, setSelectedOption] = useState(
    getSelectedValue(configuration?.selectedOption, configuration?.options)
  );

  const [graphLoader, setGraphLoader] = useState(false);

  const ref = useRef();

  useDetectOutsideClick(ref, (e) => {
    e.stopPropagation();
    setShowMoreOptions(false);
  });

  const getGraphExpandIcon = () =>
    showGraphExpandIcon ? (
      <div
        role="button"
        tabIndex={0}
        className="p-[4px] mr-[10px] bg-[#F0F2F6] border border-transparent rounded-[3px] cursor-pointer"
        onClick={() => setShowGraphModal(true)}
        onKeyPress={(e) => {
          handleKeyPress(e, true, setShowGraphModal);
        }}
      >
        <ExpandIcon className="expand-icon" />
      </div>
    ) : null;

  const exportDownload = async (service, granularity, extraProps) => {
    setExcelLoading(true);
    try {
      const response = await onExcelDownload(service, granularity?.toUpperCase(), extraProps);
      downloadExcel(response);
    } finally {
      setExcelLoading(false);
    }
  };

  const getExcelButton = (data, service) => (
    <div className="excel-button ml-[16px]">
      <ExcelDownload
        excelLoading={excelLoading}
        downloadHandler={() => {
          if (apiKey && activeGranularity) {
            exportDownload(apiKey, activeGranularity, {
              selectedTab: configuration?.tabArr?.find((_) => _?.label === service)?.value,
              selectedOption: configuration?.options?.find(
                (_) => _.value === configuration?.selectedOption
              )?.value,
              ...getHeaderValues(headerComponent),
            });
          } else {
            onExcelDownload(name);
          }
        }}
        hasData={data ? !data.length : !(graphData?.dataset?.length || graphData?.data)}
      />
    </div>
  );

  const tabClicked = async (item, componentType = "tab") => {
    if (item?.label === selectedTab) {
      return;
    }
    setGraphLoader(true);
    if (componentType === "tab") {
      setSelectedTab(item?.label);
      configuration.selectedTab = item?.value;
    }
    if (componentType === "select") {
      setSelectedOption(item);
      configuration.selectedOption = item?.value;
    }
    const response = await configuration.serviceCall({
      selected: apiKey,
      name: configuration?.name,
      reportGranularity: activeGranularity?.toUpperCase(),
      configuration,
    });
    const config = { ...configuration, data: response?.data };
    updateConfig(configuration?.name, config);
    setGraphLoader(false);
  };
  return (
    <div className={`billing_summary_wrapper s3GraphCont ${wrapperClass}`}>
      <div className="billing_summary">
        <div className="Graph_table_wrapper box-styling">
          <div className="Graph_table_heading border-bottom-grey ">
            <div className="d_flex heading_wrapper">
              <h3 className={`heading main-text-bold ${dataTransferHeading}`}>
                {!!heading && heading}
              </h3>
            </div>
            <div className="flex items-center gap-[10px]">
              <Select
                options={configuration?.options}
                handleChange={tabClicked}
                selected={selectedOption}
              />
              {showMoreIcon ? (
                <div
                  className={`showMoreIcon flex h-[24] ${showMoreOptions ? "activeIcon" : ""}`}
                  ref={ref}
                >
                  {getGraphExpandIcon()}

                  <MoreIcon
                    onClick={() => setShowMoreOptions(!showMoreOptions)}
                    className={`more-icon cursor-pointer ${showMoreOptions ? "active" : ""}`}
                  />
                  {showMoreOptions && (
                    <div className="more_container">
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          setShowModal(true);
                        }}
                        onKeyPress={(event) => {
                          handleKeyPress(event, true, setShowModal);
                        }}
                        className="option mb-[3px]"
                      >
                        View Table
                      </div>
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          if (apiKey && activeGranularity) {
                            exportDownload(
                              apiKey,
                              activeGranularity,
                              {
                                selectedTab: getSelectedValue(selectedTab, configuration?.tabArr)
                                  ?.value,
                                selectedOption: getSelectedValue(
                                  configuration?.selectedOption,
                                  configuration?.options
                                )?.value,
                                ...getHeaderValues(headerComponent),
                              },
                              { selectedOption }
                            );
                          } else {
                            onExcelDownload(name);
                          }
                          setShowMoreOptions(false);
                        }}
                        onKeyPress={(event) => {
                          if (apiKey && activeGranularity) {
                            handleKeyPress(event, apiKey, exportDownload, activeGranularity);
                          } else {
                            handleKeyPress(event, name, onExcelDownload);
                          }
                          setShowMoreOptions(false);
                        }}
                        className="option"
                        disabled={!graphtableData?.length}
                      >
                        Export Excel
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <div>
            <Tabs
              tabs={configuration?.tabArr}
              tabClicked={tabClicked}
              selected={selectedTab}
              wrapperClass="mt-[16px] justify-center flex"
              isLoading={graphLoader}
            />
          </div>
          <div className="data_renderer">
            {graphLoading || graphLoader ? (
              <Loader />
            ) : (
              <div>
                {type === "table" ? (
                  <div className="table_container_wrapper">
                    {graphtableData?.length ? (
                      <SingleLevelTable
                        headings={graphTableHeader}
                        data={graphtableData}
                        sortedIcon
                        footer={graphTableFooter}
                        newFooter={graphTableFooter}
                        heading={`table_${id}`}
                        V2
                        lastColumnSticky
                        showSortingOnTotal={showSortingOnTotal}
                      />
                    ) : (
                      <div className="no_data">
                        <h5>No Data Available</h5>
                      </div>
                    )}
                  </div>
                ) : (
                  <Chart {...props} updatedWidth={width} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {showGraphModal && (
        <ExpandedGraph
          {...props}
          selectedTab={selectedTab}
          selectedDropdownOption={selectedOption}
          showGraphModal={showGraphModal}
          setShowGraphModal={setShowGraphModal}
        />
      )}
      {showModal && (
        <TableModal
          showModal={showModal}
          setShowModal={setShowModal}
          graphTableHeader={graphTableHeader}
          graphtableData={graphtableData}
          graphTableFooter={graphTableFooter}
          heading={heading}
          downloadSheetButton={getExcelButton}
          tabs={configuration.tabArr}
          selectedTab={selectedTab}
          configuration={configuration}
          activeGranularity={activeGranularity}
          apiKey={apiKey}
          selectedDropdownOption={selectedOption}
        />
      )}
    </div>
  );
}

export default Graph;
