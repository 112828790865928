import { createPayload, sendPayload } from "./payload";

const logError = async ({ error, errorInfo, currentUser, token, moduleId, mavId }) => {
  if (process.env.REACT_APP_ENABLE_PROD_EB_LOGS !== "true") {
    return;
  }
  const payload = createPayload({ error, errorInfo, currentUser, token, moduleId, mavId });
  await sendPayload(payload);
};

export default logError;
