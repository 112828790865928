import React, { useEffect } from "react";
import withRouter from "../../../Common/WithRouter";
import useCudos from "./hooks/useCudos";
import ChartType from "./Components/ChartType";
import CudosTable from "./Components/Table";
import Graph from "./Components/Graph";
import { getFooterData, getGraphDataNew } from "./helper/utils";
import Loader from "../../../Common/Loader";
import PageTitle from "../../../Common/PageTitle";
import CostBreakdown from "./DataTransfer/components/CostBreakdown";
import AccessDeniedMessage from "../../../Common/AccessDeniedMessage";
import { PERMISSIONS_ENUM } from "../../../../Constants/permissions";
import { getCudosHeaderAndTab, getCudosComponent } from "./cudosUtil";
import "./style.scss";

function Cudos(props) {
  const {
    setPermission,
    accessList,
    documentTitle,
    pathArray,
    location: { pathname },
    hasPermission,
    dashboardKey,
  } = props;

  const {
    isLoading,
    activeGranularity,
    dashboardConfig,
    onGranulityChange,
    activeChartType,
    onChartTypeChange,
    selected,
    tabClicked,
    selectedService,
    granularityLoader,
    tabs,
    accountDate,
    updateConfig,
    apiKey,
    selectedDateProp,
    showGranularitySidebar,
    headerComponent,
    headerhandler,
    isHeaderLoading,
  } = useCudos({ pathname, dashboardKey });

  const { accountEndDate, accountStartDate } = accountDate;

  useEffect(() => {
    setPermission(accessList);
  }, []);

  const renderSelectedService = () =>
    Object.keys(dashboardConfig || {})?.map((component) => {
      switch (dashboardConfig[component]?.componentType) {
        case "custom": {
          const Custom = dashboardConfig[component]?.component;
          return (
            <Custom
              cardsData={dashboardConfig[component]?.data}
              summaryCardsData={dashboardConfig[component]?.data}
              className={dashboardConfig[component]?.className}
              {...dashboardConfig[component]}
              selected={selected}
            />
          );
        }
        case "graph":
          return (
            <Graph
              graph={getGraphDataNew(dashboardConfig[component], activeGranularity)}
              id={dashboardConfig[component]?.name}
              key={dashboardConfig[component]?.name}
              show={dashboardConfig[component]?.show}
              showExcelBtn
              showAxisIcons={{
                xAxis: true,
                yAxis: true,
              }}
              wrapperClass={dashboardConfig[component]?.wrapperClass}
              onExcelDownload={dashboardConfig[component]?.exportServiceCall}
              excelLoading={dashboardConfig[component]?.excelLoading}
              showSortingOnTotal
              graphLoading={isLoading || granularityLoader}
              selectedService={selected}
              activeGranularity={activeGranularity}
              configuration={dashboardConfig[component]}
              updateConfig={updateConfig}
              apiKey={apiKey}
              headerComponent={headerComponent}
            />
          );
        case "table":
          return (
            <CudosTable
              data={dashboardConfig[component]?.data}
              headers={dashboardConfig[component]?.headers}
              onExcelDownload={dashboardConfig[component]?.exportServiceCall}
              className="rds-table"
              footer={getFooterData(
                dashboardConfig[component]?.data,
                dashboardConfig[component]?.headers
              )}
              key={component}
              selected={selected}
              headerComponent={headerComponent}
            />
          );
        case "multiLevel-table":
          return (
            <CostBreakdown
              data={dashboardConfig[component]?.data}
              tabs={dashboardConfig[component]?.tabArr}
              heading={dashboardConfig[component]?.heading}
              onExcelDownload={dashboardConfig[component]?.exportServiceCall}
              selectedService={selected}
              updateConfig={updateConfig}
              configuration={dashboardConfig[component]}
            />
          );

        default:
          return null;
      }
    });

  return hasPermission(PERMISSIONS_ENUM.READ) ? (
    <div className="ck-cudos-wrapper">
      {isLoading && <Loader popup />}
      <PageTitle pageTitle={documentTitle} />
      {getCudosHeaderAndTab({
        pathArray,
        documentTitle,
        accountEndDate,
        accountStartDate,
        selectedDateProp,
        isLoading,
        tabs,
        tabClicked,
        selected,
      })}
      {showGranularitySidebar && (
        <ChartType
          onGranulityChange={onGranulityChange}
          onChartTypeChange={onChartTypeChange}
          activeChartType={activeChartType}
          activeGranulity={activeGranularity}
          granularityLoader={granularityLoader}
        />
      )}
      {getCudosComponent({
        headerComponent,
        headerhandler,
        isLoading,
        selectedService,
        renderSelectedService,
        dashboardConfig,
        tabs,
        isHeaderLoading,
      })}
    </div>
  ) : (
    <AccessDeniedMessage
      breadcrumsData={pathArray.map((item) => item.label)}
      headerName={documentTitle}
    />
  );
}

export default withRouter(Cudos);
