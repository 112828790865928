export const parseUrl = (url) => {
  try {
    return new URL(url);
  } catch (error) {
    console.error("Invalid URL:", error.message);
    return null;
  }
};

export const addSessionToUrl = (url) => {
  const currentDomain = parseUrl(url);

  const baseDomain = window.location.hostname;

  const isInternalUrl = !currentDomain?.hostname || currentDomain?.hostname === baseDomain;

  const userData = sessionStorage.getItem("userData");

  if (userData && isInternalUrl) {
    const path = url.split("?")[0]; // Extract the path
    const query = url.split("?")[1] || ""; // Extract query string, if present

    // Parse the existing query parameters
    const params = new URLSearchParams(query);
    if (params.get("session")) {
      params.delete("session");
    }
    params.set("session", sessionStorage.getItem("userData")); // Add or update the session parameter

    // Construct the updated URL
    return `${path}?${params.toString()}`;
  }
  return url;
};

export const openLinkInNewTab = (url) => {
  const updateUrl = addSessionToUrl(url);
  window.open(updateUrl, "_blank");
};
