import actions from "../Actions";
import commonService from "../Services/commonService";
import store from "../store";

export const isTunerIndirectCustomer = () => {
  const moduleList = store?.getState()?.sidebarReducer?.moduleList;
  // Define the module names for a Tuner Direct Customer
  const tunerDirectModules = ["tuner", "Preference"];

  // Extract the names of the modules from the moduleList
  const receivedModuleNames = moduleList.map((module) => module.name);

  // Check if all tunerDirectModules are present and no other modules exist
  const isDirect =
    tunerDirectModules.every((module) => receivedModuleNames.includes(module)) &&
    receivedModuleNames.length === tunerDirectModules.length;

  return !isDirect;
};

export const hasTuner = () => {
  const moduleList = store?.getState()?.sidebarReducer?.moduleList;
  return moduleList?.filter((item) => item?.name === "tuner").length > 0;
};

export const truncatedText = (text, charLimit = 20) =>
  text?.length > charLimit ? `${text.substring(0, charLimit)}...` : text;

// BELOW FUNCTIONS ARE USED TO CONTROL TUNER FEATURES FROM TCG

export const getEnabledTunerFeaturesByKey = (key, type = 0) => {
  const tabsArray = store.getState()?.TunerFeaturesReducer?.enabledFeatures?.[key]?.enabledFeatures;
  return type ? tabsArray.map((item) => item?.featureName) : tabsArray;
};

export const isTunerFeatureEnabled = (key) =>
  [key] in (store.getState().TunerFeaturesReducer.enabledFeatures || {});

export const getDashboardFeaturesByGroup = (menuData) => {
  const transformed = {};

  menuData.forEach((item) => {
    transformed[item.id] = {
      featureName: item.displayName,
      enabledFeatures:
        item.childDashboards && item.childDashboards.length > 0
          ? item.childDashboards.map((child) => ({
              featureName: child.displayName,
              featureKey: child.id,
            }))
          : [],
    };
  });

  return transformed;
};

export const getEnabledTunerFeatures = async (type) => {
  try {
    const response = await commonService.tuner.getEnabledTunerFeatures(type);
    const flattenedArray = getDashboardFeaturesByGroup(response?.data?.data);
    store.dispatch(
      actions.TunerFeaturesAction("TUNER_FEATURES", { enabledFeatures: flattenedArray })
    );
  } catch {
    //
  }
};

export function formatJSONString(jsonString) {
  try {
    // First, remove any comments from the JSON string
    // Remove single-line comments
    let cleanJson = jsonString.replace(/\/\/.*$/gm, "");
    // Remove multi-line comments
    cleanJson = cleanJson.replace(/\/\*[\s\S]*?\*\//gm, "");

    // Remove trailing commas
    cleanJson = cleanJson.replace(/,(\s*[}\]])/g, "$1");

    // Parse the cleaned JSON string
    const parsedJSON = JSON.parse(cleanJson);

    // Stringify with proper formatting (2 spaces indentation)
    const formattedJSON = JSON.stringify(parsedJSON, null, 3);

    return {
      success: true,
      formatted: formattedJSON,
      parsed: parsedJSON,
    };
  } catch (error) {
    return {
      success: false,
      error: error.message,
      original: jsonString,
    };
  }
}

export function countUniqueAccountsByAccessType(data, accessType) {
  return Object.entries(data).reduce(
    (totalCount, [, access]) => (access[accessType] ? totalCount + 1 : totalCount),
    0
  );
}

export function formatErrorMessage(response) {
  const accountIds = Object.keys(response); // Extract account IDs
  const firstAccount = accountIds[0]; // First account ID
  const secondAccount = accountIds[1]; // Second account ID

  // Construct the message
  if (accountIds.length > 2) {
    const additionalCount = accountIds.length - 2;
    return `Issue detected in ${firstAccount}, ${secondAccount} and +${additionalCount} more, for more details please check accounts`;
  }
  return `Issue detected in ${accountIds.join(", ")}, for more details please check accounts`;
}
