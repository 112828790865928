import React from "react";
import { ReactComponent as Cluster } from "../Assets/cluster.svg";
import { ReactComponent as CpuCost } from "../Assets/cpuCost.svg";
import { ReactComponent as MemoryCost } from "../Assets/memoryCost.svg";
import { ReactComponent as Nodes } from "../Assets/nodes.svg";
import { ReactComponent as Pods } from "../Assets/pods.svg";
import { ReactComponent as ActiveDaily } from "../Assets/daily-active.svg";
import { ReactComponent as InactiveActiveDaily } from "../Assets/daily-inactive.svg";
import { ReactComponent as ActiveMonthly } from "../Assets/monthly-active.svg";
import { ReactComponent as InactiveMonthly } from "../Assets/monthly-inactive.svg";
import { ReactComponent as StackGraphActive } from "../Assets/stack-graph-active.svg";
import { ReactComponent as StackGraphInActive } from "../Assets/stack-graph-inactive.svg";
import { ReactComponent as LineGraphActive } from "../Assets/line-graph-active.svg";
import { ReactComponent as LineGraphInActive } from "../Assets/line-graph-inactive.svg";
import { ReactComponent as BarChartActive } from "../Assets/bar-graph-active.svg";
import { ReactComponent as BarChartInActive } from "../Assets/bar-graph-inactive.svg";
import { ReactComponent as UnusedCostIcon } from "../Assets/unused-cost-icon.svg";
import cpuCostBackground from "../Assets/cpuCostBackground.svg";
import memoryCostBackground from "../Assets/memoryCostBackground.svg";
import nodesBackground from "../Assets/nodesBackground.svg";
import podsBackground from "../Assets/podsBackground.svg";
import clusterBackground from "../Assets/clusterBackground.svg";
import { Footer } from "../../CostBreakup/constant";
import { getCurrencySymbol } from "../../../../../Utils/commonUtils";

// eslint-disable-next-line import/prefer-default-export
export const SUMMARY_CARDS_IMAGES = [
  {
    icon: <CpuCost />,
    backgroundImage: cpuCostBackground,
  },
  {
    icon: <MemoryCost />,
    backgroundImage: memoryCostBackground,
  },
  {
    icon: <Cluster />,
    backgroundImage: clusterBackground,
  },
  {
    icon: <Nodes />,
    backgroundImage: nodesBackground,
  },
  {
    icon: <Pods />,
    backgroundImage: podsBackground,
  },
];

export const DAILY_MONTHLY_TOGGLE = [
  {
    label: "Daily",
    value: "daily",
    activeIcon: <ActiveDaily />,
    inactiveIcon: <InactiveActiveDaily />,
  },
  {
    label: "Monthly",
    value: "monthly",
    activeIcon: <ActiveMonthly />,
    inactiveIcon: <InactiveMonthly />,
  },
];

export const IMAGES_LIST = [
  {
    text: "groupChart",
    activeIcon: <BarChartActive />,
    inactiveIcon: <BarChartInActive />,
    label: "Bar",
  },
  {
    text: "stackedcolumn2d",
    activeIcon: <StackGraphActive />,
    inactiveIcon: <StackGraphInActive />,
    label: "Stack",
  },
  {
    text: "multipleLineChart",
    activeIcon: <LineGraphActive />,
    inactiveIcon: <LineGraphInActive />,
    label: "Line",
  },
];
export const PALLETE_COLORS_CUDOS = [
  "#8ECFFF",
  "#60C0A6",
  "#F6B273",
  "#FBD064",
  "#907BE2",
  "#2A86FF",
  "#EA6AA7",
  "#4E8649",
  "#4184A1",
];
export const SELECTED_TAB = "EKS";

const headerClass =
  "!bg-[#0A3CA2] text-[#fff] px-[8px] py-[5px] border-r border-solid border-[#E0F1FF] text-left";
const bodyClass = "py-[4px] px-1.5 text-[11px] text-left";

const commonHeaders = { bodyClass, headerClass, defaultValue: "--" };
const commonHeadersRightAlign = {
  bodyClass: `${bodyClass} !text-right`,
  headerClass: `${headerClass} !text-right`,
  defaultValue: "",
};
export const TOP_20_INSTANCE_HEADERS = [
  {
    value: "Cluster Name",
    key: "cluster_name",
    ...commonHeaders,
  },
  {
    value: "Namespace",
    key: "namespace",
    ...commonHeaders,
  },
  {
    value: "Region",
    key: "region",
    ...commonHeaders,
  },
  {
    value: "Node Instance Type",
    key: "node_instance_type",
    ...commonHeaders,
  },
  {
    value: "Node Purchase Option",
    key: "node_purchase_option",
    ...commonHeaders,
  },
  {
    value: "Used CPU Cost",
    key: "used_cpu_cost",
    footerClass: "text-right",
    ...commonHeadersRightAlign,
  },
  {
    value: "Unused CPU Cost",
    key: "unused_cpu_cost",
    footerClass: "text-right",
    ...commonHeadersRightAlign,
  },
  {
    value: "Used Memory Cost",
    key: "used_memory_cost",
    footerClass: "text-right",
    ...commonHeadersRightAlign,
  },
  {
    value: "Unused Memory Cost",
    key: "unused_memory_cost",
    footerClass: "text-right",
    ...commonHeadersRightAlign,
  },
  {
    value: "Total Cost",
    ...commonHeadersRightAlign,
    key: "cost",
    decimalPlaces: 0,
  },
];

export const TOP_20_INSTANCE_HEADERS_ELASTICACHE = [
  {
    value: "Account ID",
    key: "linked_account_id",
    ...commonHeaders,
  },
  {
    value: "Instance Name",
    key: "instance_name",
    ...commonHeaders,
  },
  {
    value: "Region",
    key: "region",
    ...commonHeaders,
  },
  {
    value: "Cache Engine",
    key: "cache_engine",
    ...commonHeaders,
  },
  {
    value: "Instance Type",
    key: "instance_type",
    ...commonHeaders,
  },
  {
    value: "Pricing Type",
    key: "pricing_type",
    ...commonHeaders,
  },
  {
    value: "Usage Hours",
    key: "usage_hours",
    ...commonHeaders,
    bodyClass: "py-[4px] px-[8px] text-[11px] new-total-theme text-right",
    footerClass: "text-right",
    headerClass: `${commonHeaders.headerClass} text-right`,
    currencyRequired: false,
  },
  {
    value: "Total Cost",
    ...commonHeaders,
    bodyClass: "py-[4px] px-[8px] text-[11px] new-total-theme text-right",
    headerClass: `${commonHeaders.headerClass} text-right`,
    key: "cost",
    decimalPlaces: 0,
  },
];
export const TOP_20_INSTANCE_HEADERS_RDS = [
  {
    value: "Account ID",
    key: "linked_account_id",
    ...commonHeaders,
    bodyClass: `${commonHeaders.bodyClass} text-left`,
    headerClass: `${commonHeaders.headerClass} text-left`,
  },
  {
    value: "Instance Name",
    key: "instance_name",
    ...commonHeaders,
    bodyClass: `${commonHeaders.bodyClass} text-left`,
    headerClass: `${commonHeaders.headerClass} text-left`,
  },
  {
    value: "Region",
    key: "region",
    ...commonHeaders,
    bodyClass: `${commonHeaders.bodyClass} text-left`,
    headerClass: `${commonHeaders.headerClass} text-left`,
  },
  {
    value: "DB Engine",
    key: "db_engine",
    ...commonHeaders,
    bodyClass: `${commonHeaders.bodyClass} text-left`,
    headerClass: `${commonHeaders.headerClass} text-left`,
  },
  {
    value: "Instance Type",
    key: "instance_type",
    ...commonHeaders,
    bodyClass: `${commonHeaders.bodyClass} text-left`,
    headerClass: `${commonHeaders.headerClass} text-left`,
  },
  {
    value: "Pricing Type",
    key: "pricing_type",
    ...commonHeaders,
    bodyClass: `${commonHeaders.bodyClass} text-left`,
    headerClass: `${commonHeaders.headerClass} text-left`,
  },
  {
    value: "Availability Zone",
    key: "multi_az",
    ...commonHeaders,
    bodyClass: `${commonHeaders.bodyClass} text-left`,
    headerClass: `${commonHeaders.headerClass} text-left`,
  },
  {
    value: "Usage Hours",
    key: "usage_hours",
    ...commonHeaders,
    bodyClass: "py-[4px] px-[8px] text-[11px] new-total-theme text-right",
    footerClass: "text-right",
    headerClass: `${commonHeaders.headerClass} text-right !px-[4px]`,
    currencyRequired: false,
  },
  {
    value: "Total Cost",
    ...commonHeaders,
    bodyClass: "py-[4px] px-[8px] text-[11px] new-total-theme text-right",
    headerClass: `${commonHeaders.headerClass} text-right !px-[4px]`,
    key: "cost",
    decimalPlaces: 0,
  },
];

export const DATA_TRANSFER_SERVICE_MAPPING = {
  "Elastic Compute Cloud": "EC2",
  "Elastic Load Balancing": "ELB",
  "Virtual Private Cloud": "VPC",
  "Simple Storage Service": "S3",
  "Relational Database Service": "RDS",
};

export const doughnutPaletteColors = "4592FF,FFA3AB";

const GRAVITON_CONSTANT = [
  {
    key: "Graviton",
    label: "Graviton",
  },
  {
    key: "Intel",
    label: "Intel",
  },
];

const ELASTICACHE_SUMMARY_CARDS = [
  {
    key: "OnDemand",
    label: "OnDemand",
    value: GRAVITON_CONSTANT,
  },
  {
    key: "Reserved",
    label: "Reserved",
    value: GRAVITON_CONSTANT,
  },
];

const COST_BY_SERVICE_CONSTANT = [
  {
    key: "Elastic Compute Cloud",
    label: "EC2",
  },
  {
    key: "Elastic Load Balancing",
    label: "ELB",
  },
];

const COST_BY_TYPE_CONSTANT = [
  {
    key: "Inter AZ",
    label: "InterAZ",
  },
  {
    key: "Internet (Out)",
    label: "Internet Out",
  },
  {
    key: "Region to Region (Out)",
    label: "Region to Region",
  },
];

const DATA_TRANSFER_CARDS = [
  {
    key: "Cost by Service",
    label: "Cost by Service",
    value: COST_BY_SERVICE_CONSTANT,
  },
  {
    key: "Cost by Type",
    label: "Cost by Type",
    value: COST_BY_TYPE_CONSTANT,
  },
];

const SUMMARY_CARDS_USED = [
  {
    label: "Used",
    key: "Used",
  },
  {
    key: "Unused",
    label: "Unused",
  },
];

const RDS_HIGHLIGHTS = [
  {
    key: "RDS Highlights",
    label: "RDS Highlights",
    value: [
      {
        key: "Backup",
        label: "Backup",
      },
      {
        key: "Aurora Serverless",
        label: "Aurora Serverless",
      },
      {
        key: "GP3",
        label: "GP3",
      },
      {
        key: "PIOPS",
        label: "PIOPS",
      },
      {
        key: "Aurora Storage",
        label: "Aurora Storage",
      },
      {
        key: "GP2",
        label: "GP2",
      },
      {
        key: "Extended Support",
        label: "Extended Support",
      },
    ],
  },
];

const OPENSEARCH_HIGHLIGHTS = [
  {
    key: "OpenSearch Highlights",
    label: "OpenSearch Highlights",
    value: [
      {
        key: "Compute",
        label: "Compute",
      },
      {
        key: "Storage",
        label: "Storage",
      },
      {
        key: "Serverless",
        label: "Serverless",
      },
    ],
  },
];

const REDSHIFT_HIGHLIGHTS = [
  {
    key: "Redshift Highlights",
    label: "Redshift Highlights",
    value: [
      {
        key: "Storage",
        label: "Storage",
      },
      {
        key: "Snapshot",
        label: "Snapshot",
      },
      {
        key: "Serverless",
        label: "Serverless",
      },
    ],
  },
];

export const SUMMARY_CARDS_CONSTANT = {
  EKS: [
    {
      key: "Total CPU Cost",
      label: "Total CPU Cost",
      value: SUMMARY_CARDS_USED,
    },
    {
      key: "Total Memory Cost",
      label: "Total Memory Cost",
      value: SUMMARY_CARDS_USED,
    },
    {
      key: "Total Cluster",
      label: "Total Cluster",
      value: [
        {
          key: "Total Cluster",
          label: "Total Cluster",
        },
      ],
    },
    {
      key: "Total Nodes",
      label: "Total Nodes",
      value: [
        {
          key: "Total Nodes",
          label: "Total Nodes",
        },
      ],
    },
    {
      key: "Total Pods",
      label: "Total Pods",
      value: [
        {
          key: "Total Pods",
          label: "Total Pods",
        },
      ],
    },
  ],
  ELASTICACHE: ELASTICACHE_SUMMARY_CARDS,
  RDS: ELASTICACHE_SUMMARY_CARDS,
  rds_highlights: RDS_HIGHLIGHTS,
  DATA_TRANSFER: DATA_TRANSFER_CARDS,
  OPENSEARCH: OPENSEARCH_HIGHLIGHTS,
  REDSHIFT: REDSHIFT_HIGHLIGHTS,
};

export const DATA_EC2 = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
    sortedIcon: true,
    hideSortingIcon: true,
  },
  {
    Header: "Name",
    accessor: "name",
    id: "name",
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
    sortedIcon: true,
    hideSortingIcon: true,
  },
  {
    Header: "Instance ID",
    accessor: "instance_id",
    id: "instance_id",
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
    sortedIcon: true,
    hideSortingIcon: true,
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
    sortedIcon: true,
    hideSortingIcon: true,
  },
  {
    Header: "Inter AZ ",
    accessor: "inter_az_usage",
    id: "inter_az_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.inter_az_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "inter_az_usage",
        Footer: (info) => Footer(info, "inter_az_usage"),
        align: "Right",
        sortedIcon: true,
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.inter_az_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "inter_az_cost",
        Footer: (info) => `${getCurrencySymbol()}${Footer(info, "inter_az_cost")}`,
        align: "Right",
        sortedIcon: true,
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
    ],
  },

  {
    Header: "Internet (Out) ",
    accessor: "internet_out_usage",
    id: "internet_out_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.internet_out_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "internet_out_usage",
        Footer: (info) => Footer(info, "internet_out_usage"),
        align: "Right",
        sortedIcon: true,
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.internet_out_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "internet_out_cost",
        Footer: (info) => Footer(info, "internet_out_cost"),
        align: "Right",
        sortedIcon: true,
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
    ],
  },
  {
    Header: "Region to Region ",
    accessor: "region_to_region_usage",
    id: "region_to_region_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.region_to_region_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "region_to_region_usage",
        Footer: (info) => Footer(info, "region_to_region_usage"),
        align: "Right",
        sortedIcon: true,
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.region_to_region_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "region_to_region_cost",
        Footer: (info) => Footer(info, "region_to_region_cost"),
        align: "Right",
        sortedIcon: true,
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
    ],
  },
  {
    Header: "Total Cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "total",
    sortedIcon: true,
    hideSortingIcon: true,
    Footer: (info) => `${getCurrencySymbol()}${Footer(info, "total")}`,
    align: "Right",
  },
];
export const DATA_ELB = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
    hideSortingIcon: true,
  },
  {
    Header: "Load Balancer",
    accessor: "load_balancer",
    id: "load_balancer",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
    hideSortingIcon: true,
  },

  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
    hideSortingIcon: true,
  },

  {
    Header: "Inter AZ ",
    accessor: "inter_az_usage",
    id: "inter_az_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.inter_az_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "inter_az_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inter_az_usage"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.inter_az_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "inter_az_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inter_az_cost"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
    ],
  },

  {
    Header: "Internet (Out)",
    accessor: "internet_out_usage",
    id: "internet_out_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.internet_out_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "internet_out_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "internet_out_usage"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.internet_out_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "internet_out_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "internet_out_cost"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
    ],
  },
  {
    Header: "Region to Region ",
    accessor: "region_to_region_usage",
    id: "region_to_region_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.region_to_region_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "region_to_region_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "region_to_region_usage"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.region_to_region_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "region_to_region_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "region_to_region_cost"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
    ],
  },

  {
    Header: "Total Cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "total",
    sortedIcon: true,
    hideSortingIcon: true,
    Footer: (info) => Footer(info, "total"),
  },
];

export const DATA_VPC = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
    hideSortingIcon: true,
  },
  {
    Header: "VPC",
    accessor: "vpc",
    id: "vpc",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
    hideSortingIcon: true,
  },

  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
    hideSortingIcon: true,
  },
  {
    Header: "Inter AZ",
    accessor: "inter_az_usage",
    id: "inter_az_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.inter_az_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "inter_az_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inter_az_usage"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.inter_az_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "inter_az_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inter_az_cost"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
    ],
  },

  {
    Header: "Internet (Out) ",
    accessor: "internet_out_usage",
    id: "internet_out_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.internet_out_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "internet_out_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "internet_out_usage"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.internet_out_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "internet_out_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "internet_out_cost"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
    ],
  },
  {
    Header: "Region to Region ",
    accessor: "region_to_region_usage",
    id: "region_to_region_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.region_to_region_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "region_to_region_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "region_to_region_usage"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.region_to_region_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "region_to_region_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "region_to_region_cost"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
    ],
  },
  {
    Header: "Total Cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "total",
    sortedIcon: true,
    hideSortingIcon: true,
    Footer: (info) => Footer(info, "total"),
  },
];

export const DATA_S3 = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
    hideSortingIcon: true,
  },
  {
    Header: "Bucket Name",
    accessor: "bucket_name",
    id: "bucket_name",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
    hideSortingIcon: true,
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
    hideSortingIcon: true,
  },
  {
    Header: "Inter AZ",
    id: "inter_az_usage",
    accessor: "inter_az_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.inter_az_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "inter_az_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inter_az_usage"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.inter_az_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "inter_az_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inter_az_cost"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
    ],
  },

  {
    Header: "Internet (Out) ",
    accessor: "internet_out_usage",
    id: "internet_out_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.internet_out_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "internet_out_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "internet_out_usage"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.internet_out_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "internet_out_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "internet_out_cost"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
    ],
  },
  {
    Header: "Region to Region ",
    accessor: "region_to_region_usage",
    id: "region_to_region_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.region_to_region_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "region_to_region_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "region_to_region_usage"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.region_to_region_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "region_to_region_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "region_to_region_cost"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
    ],
  },
  {
    Header: "Others ",
    accessor: "others_usage",
    id: "others_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.others_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "others_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "others_usage"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.others_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "others_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "others_cost"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
    ],
  },

  {
    Header: "Total Cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "total",
    sortedIcon: true,
    hideSortingIcon: true,
    Footer: (info) => Footer(info, "total"),
  },
];

export const DATA_RDS = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
    hideSortingIcon: true,
  },
  {
    Header: "Instance Name",
    accessor: "rds_instance_name",
    id: "rds_instance_name",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
    hideSortingIcon: true,
  },

  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
    hideSortingIcon: true,
  },

  {
    Header: "Inter AZ",
    accessor: "inter_az_usage",
    id: "inter_az_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.inter_az_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "inter_az_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inter_az_usage"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.inter_az_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "inter_az_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inter_az_cost"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
    ],
  },

  {
    Header: "Internet (Out) ",
    accessor: "internet_out_usage",
    id: "internet_out_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.internet_out_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "internet_out_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "internet_out_usage"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.internet_out_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "internet_out_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "internet_out_cost"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
    ],
  },
  {
    Header: "Region to Region ",
    accessor: "region_to_region_usage",
    id: "region_to_region_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.region_to_region_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "region_to_region_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "region_to_region_usage"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.region_to_region_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "region_to_region_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "region_to_region_cost"),
        align: "Right",
        hideSortingIcon: true,
        headerTextClass: "!justify-end",
      },
    ],
  },

  {
    Header: "Total Cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "total",
    sortedIcon: true,
    hideSortingIcon: true,
    Footer: (info) => Footer(info, "total"),
  },
];

export const TOP_20_S3_HEADERS = [
  {
    value: "Bucket Name",
    key: "Bucket Name",
    ...commonHeaders,
  },
  {
    value: "Region",
    key: "Region",
    ...commonHeaders,
  },
  {
    value: "Standard Storage",
    key: "Standard Storage",
    ...commonHeadersRightAlign,
  },
  {
    value: "Standard API",
    key: "Standard API",
    ...commonHeadersRightAlign,
  },
  {
    value: "Standard IA",
    key: "Standard IA",
    ...commonHeadersRightAlign,
  },
  {
    value: "Internet Data Transfer Out",
    key: "Internet Data Transfer Out",
    ...commonHeadersRightAlign,
  },
  {
    value: "Regional Data Transfer Out",
    key: "Regional Data Transfer Out",
    ...commonHeadersRightAlign,
  },
  {
    value: "Standard API IA",
    key: "Standard API IA",
    ...commonHeadersRightAlign,
  },
  {
    value: "Glacier Storage",
    key: "Glacier Storage",
    ...commonHeadersRightAlign,
  },
  {
    value: "Reduced Redundancy Storage",
    key: "Reduced Redundancy Storage",
    ...commonHeadersRightAlign,
  },
  {
    value: "Storage Zone IA",
    key: "Storage Zone IA",
    ...commonHeadersRightAlign,
  },
  {
    value: "Others",
    key: "Others",
    ...commonHeadersRightAlign,
  },
  {
    value: "Total Cost",
    key: "Total Cost",
    ...commonHeadersRightAlign,
  },
];

// OpenSearch
export const TOP_20_INSTANCE_HEADERS_OPENSEARCH = [
  {
    value: "Account ID",
    key: "linked_account_id",
    ...commonHeaders,
  },
  {
    value: "Instance Name",
    key: "instance_name",
    ...commonHeaders,
  },
  {
    value: "Region",
    key: "region",
    ...commonHeaders,
  },
  {
    value: "Instance Type",
    key: "instance_type",
    ...commonHeaders,
  },
  {
    value: "Pricing Type",
    key: "pricing_type",
    ...commonHeaders,
  },
  {
    value: "Usage Hours",
    key: "usage_hours",
    ...commonHeadersRightAlign,
    footerClass: "text-right",
    currencyRequired: false,
  },
  {
    value: "Total Cost",
    ...commonHeadersRightAlign,
    key: "cost",
    decimalPlaces: 2,
  },
];

// Redshift
export const TOP_20_INSTANCE_HEADERS_REDSHIFT = [
  {
    value: "Account ID",
    key: "linked_account_id",
    ...commonHeaders,
  },
  {
    value: "Cluster Name",
    key: "instance_name",
    ...commonHeaders,
  },
  {
    value: "Region",
    key: "region",
    ...commonHeaders,
  },
  {
    value: "Instance Type",
    key: "instance_type",
    ...commonHeaders,
  },
  {
    value: "Pricing Type",
    key: "pricing_type",
    ...commonHeaders,
  },
  {
    value: "Usage Hours",
    key: "usage_hours",
    ...commonHeadersRightAlign,
    footerClass: "text-right",
    decimalPlaces: 2,
    currencyRequired: false,
  },
  {
    value: "Total Cost",
    ...commonHeadersRightAlign,
    key: "cost",
    decimalPlaces: 2,
  },
];

// EC2

export const TOP_20_INSTANCE_HEADERS_EC2 = [
  {
    value: "Account ID",
    key: "linked_account_id",
    ...commonHeaders,
  },
  {
    value: "Name",
    key: "name",
    ...commonHeaders,
  },
  {
    value: "Instance Id",
    key: "instance_id",
    ...commonHeaders,
  },
  {
    value: "Region",
    key: "region",
    ...commonHeaders,
  },
  {
    value: "Instance Type",
    key: "instance_type",
    ...commonHeaders,
  },
  {
    value: "Pricing Type",
    key: "pricing_type",
    ...commonHeaders,
  },
  {
    value: "Operating System",
    key: "operating_system",
    ...commonHeaders,
  },
  {
    value: "Usage Hours",
    key: "usage_hours",
    ...commonHeadersRightAlign,
    footerClass: "text-right",
    currencyRequired: false,
  },
  {
    value: "Total Cost",
    ...commonHeadersRightAlign,
    key: "cost",
    decimalPlaces: 2,
  },
];

export const UNUSED_COST_CARD = {
  icon: <UnusedCostIcon />,
  subtitle: "(Amortized Cost)",
  title: "Unused RI & SP",
  value: "--",
};
