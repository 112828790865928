/* eslint-disable max-lines-per-function */
import React from "react";
import { ModuleNameAzureInsights } from "../../../../../../Constants/textConstant";
import { getSessionStorageUserData } from "../../../../../../Utils/commonUtils";
import { copyTooltip } from "./service";
import { ReactComponent as InfoIcon } from "../../../../../../Assets/images/ic-info.svg";
import AddIcon from "../../../../../../Assets/icons/add-icon.svg";
import store from "../../../../../../store";

const isPurchaseReservation = () => {
  const data = new URLSearchParams(window.location.search);
  const arr = store.getState()?.sidebarReducer?.additionalButtons;
  return arr?.find((item) =>
    item?.displayName?.toLowerCase()?.includes(data?.get("selected")?.toLowerCase())
  );
};

export const summaryEC2New = (handleBuyAgain = null) => [
  {
    Header: "Account ID",
    accessor: "account_id",
    id: "account_id",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    render: (item) => copyTooltip(item?.account_name, "Account Name", item?.account_id, true),
  },
  {
    Header: "Reservation ID",
    accessor: "reservation_id",
    id: "reservation_id",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    render: (item) =>
      copyTooltip(
        item?.reservation_id,
        "Reservation ID",
        `${item?.reservation_id.slice(0, 4)}...${item?.reservation_id.slice(-4)}`,
        false
      ),
  },
  {
    Header: "Expiry (Days)",
    accessor: "expiry_in_days",
    id: "expiry_in_days",
    bodyClass: "!text-right",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => (
      <div className="expiry-days">
        {item?.expiry_in_days < 31 && isPurchaseReservation() ? (
          <button type="button" onClick={() => handleBuyAgain(item)}>
            Buy Again
          </button>
        ) : null}
        {item?.expiry_in_days < 0 ? "NA" : item?.expiry_in_days}
      </div>
    ),
  },
  {
    Header: "Platform",
    accessor: "platform",
    id: "platform",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Instance Type",
    accessor: "instance_type",
    id: "instance_type",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Term",
    accessor: "term",
    id: "term",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
    id: "quantity",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => <div className="text-right">{item?.quantity}</div>,
  },
  {
    Header: "Payment Option",
    accessor: "payment_option",
    id: "payment_option",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
    id: "start_date",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    sortKey: "startDateEpoc",
  },
  {
    Header: "End Date",
    accessor: "end_date",
    id: "end_date",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    sortKey: "endDateEpoc",
  },
  {
    Header: "Hourly Price ($)",
    accessor: "hourly_price",
    id: "hourly_price",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => <div className="text-right">{item?.hourly_price}</div>,
  },
  {
    Header: "Upfront Price ($)",
    accessor: "upfront_price",
    id: "upfront_price",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => <div className="text-right">{item?.upfront_price}</div>,
  },
  {
    Header: "Offering Class",
    accessor: "offering_class",
    id: "offering_class",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Tenancy",
    accessor: "tenancy",
    id: "tenancy",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Purchase Mechanism",
    accessor: "purchase_mechanism",
    id: "purchase_mechanism",
    sortedIcon: true,
    hideFilters: true,
  },
  {
    Header: "State",
    accessor: "state",
    id: "state",
    sortedIcon: true,
    hideFilters: true,
  },
  // {
  //   Header: "Created At",
  //   accessor: "created_at",
  //   id: "created_at",
  //   sortedIcon: true,
  //   hideFilters: true,
  // },
  // {
  //   Header: "Created By",
  //   accessor: "created_by",
  //   id: "created_by",
  //   sortedIcon: true,
  //   hideFilters: true,
  // },
];

export const summaryRDSNew = (handleBuyAgain = null) => [
  {
    Header: "Account ID",
    accessor: "account_id",
    id: "account_id",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    render: (item) => copyTooltip(item?.account_name, "Account Name", item?.account_id, true),
  },
  {
    Header: "Reservation ID",
    accessor: "reservation_id",
    id: "reservation_id",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    render: (item) =>
      copyTooltip(
        item?.reservation_id,
        "Reservation ID",
        `${item?.reservation_id.slice(0, 4)}...${item?.reservation_id.slice(-4)}`,
        false
      ),
  },
  {
    Header: "Expiry (Days)",
    accessor: "expiry_in_days",
    id: "expiry_in_days",
    bodyClass: "!text-right",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => (
      <div className="expiry-days">
        {item?.expiry_in_days < 31 && isPurchaseReservation() ? (
          <button type="button" onClick={() => handleBuyAgain(item)}>
            Buy Again
          </button>
        ) : null}
        {item?.expiry_in_days < 0 ? "NA" : item?.expiry_in_days}
      </div>
    ),
  },
  {
    Header: "Engine Type",
    accessor: "engine_type",
    id: "engine_type",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Instance Type",
    accessor: "instance_type",
    id: "instance_type",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Term",
    accessor: "term",
    id: "term",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
    id: "quantity",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => <div className="text-right">{item?.quantity}</div>,
  },
  {
    Header: "Payment Option",
    accessor: "payment_option",
    id: "payment_option",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
    id: "start_date",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    sortKey: "startDateEpoc",
  },
  {
    Header: "End Date",
    accessor: "end_date",
    id: "end_date",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    sortKey: "endDateEpoc",
  },
  {
    Header: "Availability",
    accessor: "availability",
    id: "avaiaibility",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Hourly Price ($)",
    accessor: "hourly_price",
    id: "hourly_price",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => <div className="text-right">{item?.hourly_price}</div>,
  },
  {
    Header: "Upfront Price ($)",
    accessor: "upfront_price",
    id: "upfront_price",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => <div className="text-right">{item?.upfront_price}</div>,
  },
  {
    Header: "Purchase Mechanism",
    accessor: "purchase_mechanism",
    id: "purchase_mechanism",
    sortedIcon: true,
    hideFilters: true,
  },
  {
    Header: "State",
    accessor: "state",
    id: "state",
    sortedIcon: true,
    hideFilters: true,
  },
  // {
  //   Header: "Created At",
  //   accessor: "created_at",
  //   id: "created_at",
  //   sortedIcon: true,
  //   hideFilters: true,
  // },
  // {
  //   Header: "Created By",
  //   accessor: "created_by",
  //   id: "created_by",
  //   sortedIcon: true,
  //   hideFilters: true,
  // },
];

export const summaryElastiCacheNew = (handleBuyAgain = null) => [
  {
    Header: "Account ID",
    accessor: "account_id",
    id: "account_id",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    render: (item) => copyTooltip(item?.account_name, "Account Name", item?.account_id, true),
  },
  {
    Header: "Reservation ID",
    accessor: "reservation_id",
    id: "reservation_id",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    render: (item) =>
      copyTooltip(
        item?.reservation_id,
        "Reservation ID",
        `${item?.reservation_id.slice(0, 4)}...${item?.reservation_id.slice(-4)}`,
        false
      ),
  },
  {
    Header: "Expiry (Days)",
    accessor: "expiry_in_days",
    id: "expiry_in_days",
    bodyClass: "!text-right",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => (
      <div className="expiry-days">
        {item?.expiry_in_days < 31 &&
        item?.payment_option?.toLowerCase() !== "heavy utilization" &&
        isPurchaseReservation() ? (
          <button type="button" onClick={() => handleBuyAgain(item)}>
            Buy Again
          </button>
        ) : null}
        {item?.expiry_in_days < 0 ? "NA" : item?.expiry_in_days}
      </div>
    ),
  },
  {
    Header: "Engine Type",
    accessor: "engine_type",
    id: "engine_type",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Node Type",
    accessor: "instance_type",
    id: "instance_type",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Term",
    accessor: "term",
    id: "term",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
    id: "quantity",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => <div className="text-right">{item?.quantity}</div>,
  },
  {
    Header: "Payment Option",
    accessor: "payment_option",
    id: "payment_option",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
    id: "start_date",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    sortKey: "startDateEpoc",
  },
  {
    Header: "End Date",
    accessor: "end_date",
    id: "end_date",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    sortKey: "endDateEpoc",
  },
  {
    Header: "Upfront Price ($)",
    accessor: "upfront_price",
    id: "upfront_price",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => <div className="text-right">{item?.upfront_price}</div>,
  },
  {
    Header: "Hourly Price ($)",
    accessor: "hourly_price",
    id: "hourly_price",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => <div className="text-right">{item?.hourly_price}</div>,
  },
  {
    Header: "Purchase Mechanism",
    accessor: "purchase_mechanism",
    id: "purchase_mechanism",
    sortedIcon: true,
    hideFilters: true,
  },
  {
    Header: "State",
    accessor: "state",
    id: "state",
    sortedIcon: true,
    hideFilters: true,
  },
  // {
  //   Header: "Created At",
  //   accessor: "created_at",
  //   id: "created_at",
  //   sortedIcon: true,
  //   hideFilters: true,
  // },
  // {
  //   Header: "Created By",
  //   accessor: "created_by",
  //   id: "created_by",
  //   sortedIcon: true,
  //   hideFilters: true,
  // },
];

export const summaryOpenSearchNew = (handleBuyAgain = null) => [
  {
    Header: "Account ID",
    accessor: "account_id",
    id: "account_id",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    render: (item) => copyTooltip(item?.account_name, "Account Name", item?.account_id, true),
  },
  {
    Header: "Reservation ID",
    accessor: "reservation_id",
    id: "reservation_id",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    render: (item) =>
      copyTooltip(
        item?.reservation_id,
        "Reservation ID",
        `${item?.reservation_id.slice(0, 4)}...${item?.reservation_id.slice(-4)}`,
        false
      ),
  },
  {
    Header: "Expiry (Days)",
    accessor: "expiry_in_days",
    id: "expiry_in_days",
    bodyClass: "!text-right",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => (
      <div className="expiry-days">
        {item?.expiry_in_days < 31 && isPurchaseReservation() ? (
          <button type="button" onClick={() => handleBuyAgain(item)}>
            Buy Again
          </button>
        ) : null}
        {item?.expiry_in_days < 0 ? "NA" : item?.expiry_in_days}
      </div>
    ),
  },
  {
    Header: "Instance Type",
    accessor: "instance_type",
    id: "instance_type",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Term",
    accessor: "term",
    id: "term",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
    id: "quantity",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => <div className="text-right">{item?.quantity}</div>,
  },
  {
    Header: "Payment Option",
    accessor: "payment_option",
    id: "payment_option",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
    id: "start_date",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    sortKey: "startDateEpoc",
  },
  {
    Header: "End Date",
    accessor: "end_date",
    id: "end_date",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    sortKey: "endDateEpoc",
  },
  {
    Header: "Upfront Price ($)",
    accessor: "upfront_price",
    id: "upfront_price",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => <div className="text-right">{item?.upfront_price}</div>,
  },
  {
    Header: "Hourly Price ($)",
    accessor: "hourly_price",
    id: "hourly_price",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => <div className="text-right">{item?.hourly_price}</div>,
  },
  {
    Header: "Purchase Mechanism",
    accessor: "purchase_mechanism",
    id: "purchase_mechanism",
    sortedIcon: true,
    hideFilters: true,
  },
  {
    Header: "State",
    accessor: "state",
    id: "state",
    sortedIcon: true,
    hideFilters: true,
  },
  // {
  //   Header: "Created At",
  //   accessor: "created_at",
  //   id: "created_at",
  //   sortedIcon: true,
  //   hideFilters: true,
  // },
  // {
  //   Header: "Created By",
  //   accessor: "created_by",
  //   id: "created_by",
  //   sortedIcon: true,
  //   hideFilters: true,
  // },
];

export const summaryRedShiftNew = (handleBuyAgain = null) => [
  {
    Header: "Account ID",
    accessor: "account_id",
    id: "account_id",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    render: (item) => copyTooltip(item?.account_name, "Account Name", item?.account_id, true),
  },
  {
    Header: "Reservation ID",
    accessor: "reservation_id",
    id: "reservation_id",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    render: (item) =>
      copyTooltip(
        item?.reservation_id,
        "Reservation ID",
        `${item?.reservation_id.slice(0, 4)}...${item?.reservation_id.slice(-4)}`,
        false
      ),
  },
  {
    Header: "Expiry (Days)",
    accessor: "expiry_in_days",
    id: "expiry_in_days",
    bodyClass: "!text-right",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => (
      <div className="expiry-days">
        {item?.expiry_in_days < 31 && isPurchaseReservation() ? (
          <button type="button" onClick={() => handleBuyAgain(item)}>
            Buy Again
          </button>
        ) : null}
        {item?.expiry_in_days < 0 ? "NA" : item?.expiry_in_days}
      </div>
    ),
  },
  {
    Header: "Node Type",
    accessor: "node_type",
    id: "node_type",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Term",
    accessor: "term",
    id: "term",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
    id: "quantity",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => <div className="text-right">{item?.quantity}</div>,
  },
  {
    Header: "Payment Option",
    accessor: "payment_option",
    id: "payment_option",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
    id: "start_date",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    sortKey: "startDateEpoc",
  },
  {
    Header: "End Date",
    accessor: "end_date",
    id: "end_date",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    sortKey: "endDateEpoc",
  },
  {
    Header: "Upfront Price ($)",
    accessor: "upfront_price",
    id: "upfront_price",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => <div className="text-right">{item?.upfront_price}</div>,
  },
  {
    Header: "Hourly Price ($)",
    accessor: "hourly_price",
    id: "hourly_price",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => <div className="text-right">{item?.hourly_price}</div>,
  },
  {
    Header: "Purchase Mechanism",
    accessor: "purchase_mechanism",
    id: "purchase_mechanism",
    sortedIcon: true,
    hideFilters: true,
  },
  {
    Header: "State",
    accessor: "state",
    id: "state",
    sortedIcon: true,
    hideFilters: true,
  },
  // {
  //   Header: "Created At",
  //   accessor: "created_at",
  //   id: "created_at",
  //   sortedIcon: true,
  //   hideFilters: true,
  // },
  // {
  //   Header: "Created By",
  //   accessor: "created_by",
  //   id: "created_by",
  //   sortedIcon: true,
  //   hideFilters: true,
  // },
];

export const summarySavingsPlanNew = (handleBuyAgain = null) => [
  {
    Header: "Account ID",
    accessor: "account_id",
    id: "account_id",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    render: (item) => copyTooltip(item?.account_name, "Account Name", item?.account_id, true),
  },
  {
    Header: "SavingsPlan ID",
    accessor: "reservation_id",
    id: "reservation_id",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    render: (item) =>
      copyTooltip(
        item?.reservation_id,
        "SavingsPlan ID",
        `${item?.reservation_id.slice(0, 4)}...${item?.reservation_id.slice(-4)}`,
        false
      ),
  },
  {
    Header: "Expiry (Days)",
    accessor: "expiry_in_days",
    id: "expiry_in_days",
    bodyClass: "!text-right",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => (
      <div className="expiry-days">
        {item?.expiry_in_days < 31 && isPurchaseReservation() ? (
          <button type="button" onClick={() => handleBuyAgain(item)}>
            Buy Again
          </button>
        ) : null}
        {item?.expiry_in_days < 0 ? "NA" : item?.expiry_in_days}
      </div>
    ),
  },
  {
    Header: "SavingsPlan Type",
    accessor: "savingplan_type",
    id: "savingplan_type",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Instance Family",
    accessor: "instance_family",
    id: "instance_family",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Term",
    accessor: "term",
    id: "term",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
  },
  {
    Header: "Hourly Commitment ($)",
    accessor: "hourly_commitment",
    id: "hourly_commitment",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => <div className="text-right">{item?.hourly_commitment}</div>,
  },
  {
    Header: "Payment Option",
    accessor: "payment_option",
    id: "payment_option",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
    id: "start_date",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    sortKey: "startDateEpoc",
  },
  {
    Header: "End Date",
    accessor: "end_date",
    id: "end_date",
    sortedIcon: true,
    hideFilters: true,
    align: "Left",
    sortKey: "endDateEpoc",
  },
  {
    Header: "Upfront Price ($)",
    accessor: "upfront_price",
    id: "upfront_cost",
    sortedIcon: true,
    hideFilters: true,
    align: "Right",
    render: (item) => <div className="text-right">{item?.upfront_price}</div>,
  },
  {
    Header: "Purchase Mechanism",
    accessor: "purchase_mechanism",
    id: "purchase_mechanism",
    sortedIcon: true,
    hideFilters: true,
  },
  {
    Header: "State",
    accessor: "state",
    id: "state",
    sortedIcon: true,
    hideFilters: true,
  },
  // {
  //   Header: "Created At",
  //   accessor: "created_at",
  //   id: "created_at",
  //   sortedIcon: true,
  //   hideFilters: true,
  // },
  // {
  //   Header: "Created By",
  //   accessor: "created_by",
  //   id: "created_by",
  //   sortedIcon: true,
  //   hideFilters: true,
  // },
];

export const summaryVirtualMachine = [
  {
    Header: "Subscription ID",
    accessor: "subscription_id",
    id: "subscription_id",
    sortedIcon: true,
    hideFilters: true,
  },
  {
    Header: "Start Date",
    accessor: "start_date",
    id: "start_date",
    sortedIcon: true,
    hideFilters: true,
  },
  {
    Header: "End Date",
    accessor: "end_date",
    id: "end_date",
    sortedIcon: true,
    hideFilters: true,
  },
  {
    Header: "Location",
    accessor: "location",
    id: "location",
    sortedIcon: true,
    hideFilters: true,
  },
  {
    Header: "Reservation Term",
    accessor: "reservation_term",
    id: "reservation_term",
    sortedIcon: true,
    hideFilters: true,
  },
  {
    Header: "Meter Name",
    accessor: "meter_name",
    id: "meter_name",
    sortedIcon: true,
    hideFilters: true,
  },
  {
    Header: "Quantity",
    accessor: "quantity",
    id: "quantity",
    sortedIcon: true,
    hideFilters: true,
  },
  {
    Header: "Payment Method",
    accessor: "payment_method",
    id: "payment_method",
    sortedIcon: true,
    hideFilters: true,
  },
  {
    Header: "Monthly Recurring Fee",
    accessor: "monthly_recurring_fee",
    id: "monthly_recurring_fee",
    sortedIcon: true,
    hideFilters: true,
  },
  {
    Header: "Upfront Fee",
    accessor: "upfront_fee",
    id: "upfront_fee",
    sortedIcon: true,
    hideFilters: true,
  },
  {
    Header: "Expiry (Days)",
    accessor: "expiry_in_days",
    id: "expiry_in_days",
    bodyClass: "!text-right",
    sortedIcon: true,
    hideFilters: true,
  },
];

export const dollarChecker = [
  "quantity",
  "quantity",
  "quantity",
  "start_date",
  "end_date",
  "quantity",
];

export const getTabs = (dashboardKey) => {
  const pathArray = window?.location?.pathname?.split("/");
  const dashboardName = dashboardKey || pathArray?.[pathArray.length - 1];
  const tabs = store
    ?.getState()
    ?.sidebarReducer?.additionalTabs?.filter(
      (item) => item?.parent === dashboardName?.toUpperCase()
    );
  return tabs;
};

export const getSummaryData = (handleBuyAgain = null) => {
  const reservationTabs = getTabs();
  let selectedTab;
  if (reservationTabs && reservationTabs?.length) {
    selectedTab = reservationTabs?.[0].title;
  }
  const { tokens: { moduleName } = {} } = getSessionStorageUserData() || {};

  const updatedDownloadList = reservationTabs?.map((tab) => ({
    value: tab?.key.replace("RESERVATIONS_SUMMARY_", ""),
    Header: tab?.key.replace("RESERVATIONS_SUMMARY_", ""),
    label: tab?.title.replace("Reservations-Summary-", ""),
  }));

  const moduleData = {
    lens: {
      breadcrumsData: ["Reservations", "Summary"],
      DownlaodTabs: [{ label: "Select All", Header: "ALL", value: "ALL" }, ...updatedDownloadList],
      selected: selectedTab,
      enum: {
        EC2: "EC2",
        RDS: "RDS",
        Redshift: "REDSHIFT",
        ElastiCache: "ELASTICACHE",
        OpenSearch: "OPENSEARCH",
        SavingsPlan: "SAVINGSPLAN",
      },
      updatedTableHeaders: {
        EC2: summaryEC2New(handleBuyAgain),
        RDS: summaryRDSNew(handleBuyAgain),
        Redshift: summaryRedShiftNew(handleBuyAgain),
        ElastiCache: summaryElastiCacheNew(handleBuyAgain),
        OpenSearch: summaryOpenSearchNew(handleBuyAgain),
        SavingsPlan: summarySavingsPlanNew(handleBuyAgain),
      },
    },

    [ModuleNameAzureInsights]: {
      tabsData: ["Virtual Machine"],
      breadcrumsData: ["Reservations", "Summary"],
      DownlaodTabs: [
        { label: "Select All", Header: "ALL", value: "ALL" },
        { label: "Virtual Machine", Header: "VIRTUAL_MACHINE", value: "VIRTUAL_MACHINE" },
      ],
      selected: "Virtual Machine",
      enum: {
        "Virtual Machine": "VIRTUAL_MACHINE",
      },
      updatedTableHeaders: {
        "Virtual Machine": summaryVirtualMachine,
      },
    },
  };
  return moduleData[moduleName] || moduleData.lens;
};

export const getReservedInstancesAvailableTabs = (tabs = []) => {
  const availableTabs = tabs?.filter((tab) => tab?.id?.includes("RESERVATIONS_SUMMARY"));
  const filteredArray = [
    "EC2",
    "RDS",
    "Redshift",
    "ElastiCache",
    "OpenSearch",
    "SavingsPlan",
    "Virtual Machine",
  ].filter((item) =>
    availableTabs.some((obj) => obj.props.pageTitle.toLowerCase() === item.toLowerCase())
  );
  return filteredArray;
};

export const buttonConfig = () => {
  const selected = new URLSearchParams(window.location.search);
  const selectedService = selected.get("selected") === "SavingsPlan";
  return [
    {
      label: selectedService ? "Request Savings Plan" : "Request Reserved Instances",
      class: "button primary medium  rounded mr-[12px] w-[205px]",
      variant: "primary",
      image: { className: "mr-[10px]", src: AddIcon, alt: "add icon" },
    },
  ];
};

export const routeState = (selected, title) => ({
  state: {
    service: selected,
    title,
  },
});

export const infoMessage = () => (
  <span className="info-on-header">
    <InfoIcon />
    Detailed breakdown of reservations, both active and expired, categorized by service at the
    instance level.
  </span>
);

export const pathArrayNew = [
  {
    label: "Home",
    path: "/",
  },
  {
    label: "Reservations",
    path: "/",
  },
  {
    label: "Summary",
    path: "/summary",
  },
];

export const tabMapper = {
  EC2: "EC2",
  RDS: "RDS",
  Redshift: "REDSHIFT",
  ElastiCache: "ELASTICACHE",
  OpenSearch: "OPENSEARCH",
  SavingsPlan: "SAVINGSPLAN",
};
