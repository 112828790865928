import React, { Component } from "react";
import moment from "moment";
import {
  numberWithCommasandDecimal,
  numberWithCommasandDecimalPercentage,
} from "../../../../../../../Utils/commonUtils";
import TooltipPopup from "../../../../../../Common/Tooltip";

import { formatValue, getDoughnutChart } from "../../../helper/utils";
import "./style.scss";

class TotalRiCoverageCost extends Component {
  renderTooltipChild = (data) => {
    const queryParams = new URLSearchParams(window.location.search);
    const updatedselectedDate = moment([
      queryParams.get("year"),
      queryParams.get("month") - 1,
    ]).format("MMMM YYYY");
    return (
      <div className="tooltip-container">
        <div className="tool_heading">{updatedselectedDate}</div>
        <div className="flex items-center item">
          <div className="flex items-center">
            <div className="w-[8px] h-[8px] bg-[#4592FF] rounded-[50%]" />
            <div className="text-[#737373] text-[11px] font-[400] ml-[12px]">
              Reservation Covered Cost
            </div>
          </div>
          <div className="text-[#2B2B2B] text-[11px] font-[500]">
            {formatValue(
              Number(data?.overallTotal) - Number(data?.onDemand),
              numberWithCommasandDecimal
            )}
          </div>
        </div>
        <div className="flex items-center item">
          <div className="flex items-center">
            <div className="w-[8px] h-[8px] bg-[#FFA3AB] rounded-[50%]" />
            <div className="text-[#737373] text-[11px] font-[400] ml-[12px]">
              Remaining OnDemand Cost
            </div>
          </div>
          <div className="text-[#2B2B2B] text-[11px] font-[500]">
            {formatValue(data?.onDemand, numberWithCommasandDecimal)}
          </div>
        </div>
        <div className="flex items-center item total">
          <div className="text-[#2B2B2B] text-[12px] font-[400]">Total Cost</div>
          <div className="text-[#2B2B2B] text-[12px] font-[500]">
            {formatValue(data?.overallTotal, numberWithCommasandDecimal)}
          </div>
        </div>
      </div>
    );
  };

  renderContent = (i) => (
    <div
      className="h-[20px] mb-[16px] coloredDiv mt-[-24px]"
      style={{ "--percentage": `${i?.coveragePercentage}%` }}
    />
  );

  render() {
    const { cardsData: { stackedGraphData, graphData, riCoverageData } = {}, subHeading } =
      this.props;
    return (
      <div className="flex flex-wrap gap-x-[16px] gap-y-[12px] doughnut-chart-wrapper-elastic">
        <div className="ri-coverage-header flex items-center justify-between">
          <div className="flex items-center gap-[6px]">
            <div className="text-[#2B2B2B] text-[16px] font-[700]">RI Coverage in Cost</div>
            <p className="text-[#737373] text-[12px] mb-[0px] italic">
              (Overall instance usage is covered by RIs)
            </p>
          </div>
        </div>
        <div className="flex justify-between ri-coverage-donut-chart-wrapper">
          {getDoughnutChart(graphData)}
          <div>
            <div className="flex items-center">
              <div className="w-[12px] h-[12px] bg-[#4592FF] rounded-[50%]" />
              <div className="text-[#1E1E1E]  text-[12px] font-[500] ml-[7px] mt-[2px]">
                Reservation Covered Cost
              </div>
            </div>
            <div className="text-[#0A3CA2] text-[25px] font-[600] ml-[20px]">
              {formatValue(
                riCoverageData?.totalRdsReservationPercentage,
                numberWithCommasandDecimalPercentage
              )}
            </div>
            <div className="text-[#999999] text-[13px] font-[500] ml-[20px]">
              {formatValue(riCoverageData?.totalRdsReservationCost, numberWithCommasandDecimal)}
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <div className="w-[12px] h-[12px] bg-[#FFA3AB] rounded-[50%]" />
              <div className="text-[#1E1E1E] text-[12px] font-[500] ml-[7px] mt-[2px]">
                Remaining OnDemand Cost
              </div>
            </div>
            <div className="text-[#0A3CA2] text-[25px] font-[600] ml-[20px]">
              {formatValue(
                riCoverageData?.totalRdsOnDemandCostPercentage,
                numberWithCommasandDecimalPercentage
              )}
            </div>
            <div className="text-[#999999] text-[13px] font-[500] ml-[20px]">
              {formatValue(riCoverageData?.totalRdsOnDemandCost, numberWithCommasandDecimal)}
            </div>
          </div>
        </div>
        <div className="bottom-container">
          <p className="heading-bottom-container">
            {subHeading || "RI Coverage per region | engine | Instance Type/Family"}
          </p>
          <div className={`pr-[14px] ${stackedGraphData?.length && "overflow-auto h-[175px]"}`}>
            {stackedGraphData?.length ? (
              stackedGraphData?.map((i, index) => (
                <div>
                  <div
                    className="text-[#2B2B2B] text-[10px] font-[500] mb-[6px]"
                    key={`stacked-${index + 1}`}
                  >
                    {i?.region}
                    {i?.dbEngine ? ` | ${i?.dbEngine}` : ""} |{" "}
                    {i?.instanceType || i?.instanceFamily}
                  </div>
                  <TooltipPopup
                    style={{
                      background: "#ffffff",
                      padding: "0px",
                    }}
                    arrow={false}
                    placement="bottom"
                    content={() => this.renderTooltipChild(i)}
                    icon={this.renderContent(i)}
                  />
                </div>
              ))
            ) : (
              <div className="no_data">
                <h5>No Data Available</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TotalRiCoverageCost;
