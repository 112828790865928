export const TITLE = "Cloudonomic ™";
export const INSTANCE_COST = "Instances-Recommendations";
export const RECOMMENDAITON_PAGE_TITLE = "Recommendations";
export const CLOUDFRONT_HELMET_TITLE = "Cloudfront-Recommendations";
export const S3_HELMET_TITLE = "S3-Recommendations";
export const EBS_HELMET_TITLE = "EBS-Recommendations";
export const EBS_SNAPSHOT_HELMET_TITLE = "EBS-Snapshot-Recommendations";
export const CLB_HELMET_TITLE = "Clb-Recommendations";
export const ModuleNameLegacyLens = "legacy-lens";
export const ModuleNameAzureInsights = "azure-insights";
export const ModuleNameGCPInsights = "gcp-insights";
export const ModuleNameTuner = "tuner";
export const NO_RESOURCE_DRILL_MODULES = [ModuleNameAzureInsights, ModuleNameGCPInsights];
