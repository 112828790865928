import { addSessionToUrl } from "./Components/Common/SessionLink/helper";

// Function to handle the mouse leave event for a context menu
export const handleMouseLeaveContextMenu = () => {
  const modifiedLinks = document.querySelectorAll("a[data-original-href]");

  modifiedLinks.forEach((link) => {
    // Restore the original href from the 'data-original-href' attribute
    const tempLink = link;
    tempLink.href = link.dataset.originalHref;

    // Remove the 'data-original-href' attribute as it is no longer needed
    delete tempLink.dataset.originalHref;
  });
};

export const handleAnchorClick = (event) => {
  const { target } = event;

  const anchor = target.closest("a");

  if (anchor && anchor.href && !anchor?.dataset?.clickEvent) {
    const isModifierClick =
      event.ctrlKey || // Ctrl key is pressed
      event.metaKey || // Command key (Mac) is pressed
      event.shiftKey || // Shift key is pressed
      event.button === 1 || // Middle mouse button is clicked
      anchor?.target === "_blank"; // Anchor is set to open in a new tab

    if (isModifierClick) {
      // Modify the URL to include session details before opening in a new tab
      const modifiedUrl = addSessionToUrl(`${anchor.href}`);

      window.open(modifiedUrl, "_blank");

      // Prevent the default click action to avoid duplicate navigation
      event.preventDefault();
    }

    // Restore any modified anchor elements after the click is processed
    handleMouseLeaveContextMenu();
  }
};

export const handleAnchorWithHref = (e) => {
  const { target } = e;

  const anchor = target.closest("a[href]");

  if (anchor) {
    // Store the original href in a data attribute for later restoration
    anchor.dataset.originalHref = anchor.href;

    // Modify the href to include session-related details using a custom function
    anchor.href = addSessionToUrl(`${anchor.href}`);
  }
};
