import React from "react";
import moment from "moment";
import {
  getCurrencySymbol,
  noop,
  numberWithCommasandDecimal,
  stringToFixedNumber,
} from "../../../../../Utils/commonUtils";
import { getNAObjectForCard } from "../cudosUtil";

import FusionChart from "../../../Common/fusionChart";
import { ReactComponent as NoDataDoughnut } from "../../../../../Assets/images/NoDataDoughnut.svg";
import {
  DATA_EC2,
  DATA_ELB,
  DATA_RDS,
  DATA_S3,
  DATA_VPC,
  DATA_TRANSFER_SERVICE_MAPPING,
  doughnutPaletteColors,
  SUMMARY_CARDS_CONSTANT,
} from "./constant";
import { graphTableLastHeader } from "../Database/constant";
import { getMaxIndex, CostBreakupHeaders, getRouteUrl } from "../../CostBreakup/costbreakupUtils";

export const getDoughnutChart = (graphData = {}) =>
  graphData?.graphData?.data?.some((item) => item?.value !== 0) ? (
    <FusionChart
      graphData={graphData?.graphData}
      chartType={graphData?.type}
      width={84}
      height={84}
      pieRadius={42}
      doughnutRadius={32}
      palettecolors={doughnutPaletteColors}
    />
  ) : (
    <div>
      <NoDataDoughnut className="h-[84px] w-[84px]" />
    </div>
  );

export const formatValue = (value, formatter) => (value ? formatter(value) : "- -");
const getCustomTooltip = (
  data,
  item,
  index,
  totalValues,
  internalItem
) => `<div class="tooltip_wrapper cudos_tooltip"> 
<div class="headingCont"> ${data?.categories?.[index]}  </div>  
<span class="row"> 
  <label>${item.seriesName}</label> 
  <b>${numberWithCommasandDecimal(internalItem)} (${item.percentageValue?.[index]}%)</b>
</span>
<div class="totalStyle">
<b>Total</b>
${numberWithCommasandDecimal(totalValues[data?.categories?.[index]])}
</div>
</div>`;

export const manipulateGraphData = (data = [], totalValues = {}) => {
  const graphDataManipulationCategories = data?.categories?.map((item) => ({
    label: item,
  }));

  const graphDataManipulationData = data?.seriesList?.map((item) => {
    const dataArray = item?.seriesValues?.map((internalItem, index) => ({
      value: internalItem,
      toolText: getCustomTooltip(data, item, index, totalValues, internalItem),
    }));
    const returnObj = {
      seriesname: item.seriesName,
      lineColor: item.lineColor, // Line color for "Last Year"
      anchorBgColor: item.anchorBgColor, // Color for anchor points and legend marker
      anchorBorderColor: item.anchorBorderColor,
      data: dataArray,
    };
    return returnObj;
  });

  const GraphObj = {
    category: graphDataManipulationCategories,
    dataset: graphDataManipulationData,
  };
  return GraphObj;
};

const manipulateTableData = (data = {}) => {
  const tableData = data?.customTableDto?.tableRows?.map((item) => {
    const tableObj = {};
    Object.keys(item.columnValuesForRow || {}).forEach((internalItem) => {
      const value = item?.columnValuesForRow[internalItem];
      tableObj[internalItem] =
        typeof value?.cellValue === "number" ? stringToFixedNumber(value?.cellValue, 2) : value;
      return tableObj[internalItem] || "";
    });
    tableObj.rowName = item.rowName;
    tableObj.total =
      typeof item?.totalValueForRow === "number"
        ? stringToFixedNumber(item?.totalValueForRow, 2)
        : item?.totalValueForRow || "";
    return tableObj;
  });
  return tableData;
};

const manipulateHeadersDataNew = (graph = {}) => {
  const headers =
    graph?.data?.customTableDto?.columnNames?.map((item) => ({
      key: item,
      value: item,
    })) || [];
  headers.unshift(graph?.firstHeader);
  headers.push(graphTableLastHeader());
  return headers;
};

const manipulateFooterDataNew = (graph = {}) => {
  const updatedTotal = {};
  const prefix = !(graph?.active !== "COST" && graph?.name === "costByTop5Usage")
    ? getCurrencySymbol()
    : "";
  Object.keys(graph?.customTableDto?.columnWiseSum || {}).forEach((item) => {
    updatedTotal[item] = `${numberWithCommasandDecimal(
      graph?.customTableDto?.columnWiseSum?.[item],
      2,
      !!prefix
    )}`;
  });
  const footer = { ...updatedTotal, rowName: "Total" };
  return footer;
};

export const getFooterData = (data, headers = []) => {
  const footerObj = {};
  headers.forEach((header, index) => {
    if (index === 0) footerObj[header.key] = "Total";
    else footerObj[header?.key] = data?.data?.data?.totalCosts?.[header.key] || "";
  });
  return footerObj;
};

export const getRouteUrlWithoutSelected = (navigate = noop, accountDate = {}, baseUrl = "") => {
  const data = new URLSearchParams(window.location.search);
  const query = localStorage.getItem("query");
  let url = "";
  // Check if "month" and "year" query params exist in the current URL
  if (data.get("month") && data.get("year")) {
    const updatedUrl = `month=${data.get("month")}&year=${data.get("year")}`;
    url = `${baseUrl}?${updatedUrl}`;
    setTimeout(() => {
      navigate(url);
    }, 50);
    return url;
  }
  // Default "month" and "year" values based on `accountEndDate`
  const defaultMonth = moment(accountDate?.accountEndDate).format("MM");
  const defaultYear = moment(accountDate?.accountEndDate).format("YYYY");

  const updatedUrl = query ? `${query}` : `?month=${defaultMonth}&year=${defaultYear}`;
  url = `${baseUrl}${updatedUrl}`;
  setTimeout(() => {
    navigate(`${baseUrl}${updatedUrl}`, {
      month: defaultMonth,
      year: defaultYear,
    });
  }, 50);
  return url;
};

export const getGraphDataNew = (graph = {}, activeGranularity = "Monthly") => {
  const graphData1 = manipulateGraphData(
    graph.data?.lineChart,
    graph?.data?.customTableDto?.columnWiseSum
  );
  const graphtableData = manipulateTableData(graph.data);
  const graphTableHeader = manipulateHeadersDataNew(graph);
  const graphTableFooter = manipulateFooterDataNew(graph.data);
  return {
    ...graph,
    rotateLabels: activeGranularity === "Monthly" ? "0" : "1",
    graphData: graphData1,
    graphtableData,
    graphTableHeader,
    graphTableFooter,
    showLabels: graph.showLabels || "0",
    trendsHeaderArray: graph.trendHeaderData,
    list: graph.list || [],
    calendarType: graph?.calendarType,
  };
};

export const getMonthCardsData = (cudosCardData = {}) => {
  const data = new URLSearchParams(window.location.search);
  const forecastData = [];
  const responseObj = cudosCardData?.analyticsTrendDto;
  for (let i = 2; i >= 0; i -= 1) {
    const date = moment(`${data.get("month")} ${data.get("year")}`, "MM-YYYY")
      .subtract(i, "months")
      .format("MMMM-YYYY");
    const selectedMonth = responseObj[date];
    if (responseObj[date]) {
      forecastData.push({
        month: moment(selectedMonth?.monthYear, "MMM-YYYY").format("MMM YYYY"),
        amount: selectedMonth?.currentMonthValue ? selectedMonth?.currentMonthValue : 0,
        percentage: selectedMonth?.percentageTrend || 0,
        differenceBetweenValues: selectedMonth?.differenceBetweenValues
          ? selectedMonth?.differenceBetweenValues
          : 0,
        forecastValue: selectedMonth?.forecastValue || 0,
        isPercentageBoxVisible: selectedMonth?.isPercentageBoxVisible,
        isForecastPresent: selectedMonth?.isForecastPresent,
      });
    } else {
      forecastData.push(getNAObjectForCard(date));
    }
  }
  return forecastData;
};

export const manipulateSummaryCardsData = (data, service) => {
  const updatedSummaryCards = SUMMARY_CARDS_CONSTANT[service]?.map((item) => {
    const updatedValues = item?.value?.map((subItem) => {
      const responseData = data?.[item?.key]?.[subItem?.key];

      if (responseData) {
        return {
          ...subItem,
          value: responseData?.value || "--",
          link: responseData?.link,
        };
      }

      return subItem;
    });

    return {
      ...item,
      value: updatedValues,
    };
  });

  return updatedSummaryCards;
};

export const createHeaders = (storageCostTable, selected) => {
  const maxIndex = getMaxIndex(storageCostTable);

  const Headers = storageCostTable?.[maxIndex];

  storageCostTable?.forEach((item, index) => {
    Object.keys(Headers).forEach((internalItem) => {
      if (!(internalItem in item)) {
        const tableObj = storageCostTable?.[index];
        tableObj[internalItem] = 0;
      }
      return internalItem;
    });
    return item;
  });

  let Headerconstant = [];

  if (Headers) {
    switch (DATA_TRANSFER_SERVICE_MAPPING[selected]) {
      case "EC2":
        Headerconstant = [...DATA_EC2];
        break;
      case "ELB":
        Headerconstant = [...DATA_ELB];
        break;
      case "VPC":
        Headerconstant = [...DATA_VPC];
        break;
      case "S3":
        Headerconstant = [...DATA_S3];
        break;
      case "RDS":
        Headerconstant = [...DATA_RDS];
        break;
      default:
        return "";
    }

    const cost = CostBreakupHeaders(Headerconstant, Headers);

    return cost;
  }
  return null;
};

export const getstorageCostTableTotal = (tableData) => {
  const storageCostTableTotalCardData = [];
  if (tableData?.totalCosts?.inter_az_usage) {
    storageCostTableTotalCardData.push({
      inter_az_usage: tableData?.totalCosts?.inter_az_usage,
      inter_az_cost: tableData?.totalCosts?.inter_az_cost,
      column_Inter: "Inter AZ (GB)",
      column_Cost: `Cost (${getCurrencySymbol()})`,
      column_Label: "Inter AZ",
      column: "Inter AZ",
      total: 0,
      column_Label_ClassName: "right-[-19px]",
    });
  }
  if (tableData?.totalCosts?.internet_out_usage) {
    storageCostTableTotalCardData.push({
      inter_az_usage: tableData?.totalCosts?.internet_out_usage,
      inter_az_cost: tableData?.totalCosts?.internet_out_cost,
      column_Inter: "Internet (Out) (GB)",
      column_Cost: `Cost (${getCurrencySymbol()})`,
      column_Label: "Internet (Out)",
      column: "Internet (Out)",
      total: 0,
      column_Label_ClassName: "right-[-27px]",
    });
  }
  if (tableData?.totalCosts?.region_to_region_usage) {
    storageCostTableTotalCardData.push({
      inter_az_usage: tableData?.totalCosts?.region_to_region_usage,
      inter_az_cost: tableData?.totalCosts?.region_to_region_cost,
      column_Inter: "Region to Region (GB)",
      column_Cost: `Cost (${getCurrencySymbol()})`,
      column_Label: "Region to Region",
      column: "Region to Region",
      total: 0,
      column_Label_ClassName: "right-[-39px]",
    });
  }
  if (tableData?.totalCosts?.others_usage) {
    storageCostTableTotalCardData?.push({
      inter_az_usage: tableData?.totalCosts?.others_usage,
      inter_az_cost: tableData?.totalCosts?.others_cost,
      column_Inter: "Others (GB)",
      column_Cost: `Cost (${getCurrencySymbol()})`,
      column_Label: "Others",
      column: "Others",
      total: 0,
      column_Label_ClassName: "right-[-16px]",
    });
  }
  if (tableData?.totalCosts?.total) {
    storageCostTableTotalCardData.push({
      total: tableData?.totalCosts?.total,
      column: `Total (${getCurrencySymbol()})`,
    });
  }

  return storageCostTableTotalCardData;
};

export const getApiFun = (configObj, key, granularity, tab, payload) =>
  configObj[key]?.serviceCall({
    selected: tab,
    name: key,
    reportGranularity: configObj[key]?.isGranularityApplied ? granularity.toUpperCase() : undefined,
    configuration: configObj[key],
    header: payload || {},
  });

export const updateRoutes = (tabs, accountDate, pathname, navigate) => {
  const tab = tabs?.[0];
  if (tabs?.length) {
    return getRouteUrl(navigate, accountDate, tab, pathname, tabs);
  }
  return getRouteUrlWithoutSelected(navigate, accountDate, pathname);
};

export const getSelectedValue = (value, arr = []) =>
  value && arr.find((_) => _.value === value) ? arr.find((_) => _.value === value) : arr?.[0];

export const dashboardAPICalls = async ({
  config = {},
  isGranularityUpdated = false,
  granularity = "",
  dashboardConfiguration = {},
  headerPayload = {},
}) => {
  const apiArr = [];
  const configObj = config;
  Object.keys(configObj || {}).forEach((key) => {
    if (!isGranularityUpdated || configObj[key]?.isGranularityApplied) {
      apiArr.push(
        getApiFun(
          configObj,
          key,
          granularity,
          dashboardConfiguration?.name,
          Object?.keys(headerPayload)?.length ? headerPayload : null
        )
      );
    }
  });
  const response = await Promise.all(apiArr);
  response.forEach((res) => {
    if (configObj[res.name]) {
      configObj[res.name].data = res?.data;
      configObj[res.name].tabArr = res?.tabArr;
      configObj[res.name].options = res?.options;
      configObj[res.name].selectedOption = res?.selectedOption;
    }
  });
  return {
    config: configObj,
  };
};

export const getHeaderPayload = (payload, header) => {
  const payloadClone = payload;
  if (Object?.keys(header)?.length) {
    Object?.keys(header)?.forEach((ele) => {
      payloadClone[ele] = header?.[ele] || null;
    });
  }
};
