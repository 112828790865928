// Permissions Enums :
export const PERMISSIONS_ENUM = {
  WRITE: "WRITE",
  READ: "READ",
  DELETE: "DELETE",
};

export const TABLE_ICONS_PERMISSIONS = {
  preview: PERMISSIONS_ENUM.READ,
  toggle: PERMISSIONS_ENUM.WRITE,
  edit: PERMISSIONS_ENUM.WRITE,
  save: PERMISSIONS_ENUM.WRITE,
  add: PERMISSIONS_ENUM.WRITE,
  promoteRevamp: PERMISSIONS_ENUM.WRITE,
  editRevamp: PERMISSIONS_ENUM.WRITE,
  delete: PERMISSIONS_ENUM.DELETE,
  date_time_calender: PERMISSIONS_ENUM.READ,
  cancel: PERMISSIONS_ENUM.WRITE,
  promote: PERMISSIONS_ENUM.WRITE,
  resendVerification: PERMISSIONS_ENUM.WRITE,
  input: PERMISSIONS_ENUM.WRITE,
  inputArrow: PERMISSIONS_ENUM.WRITE,
  addCart: PERMISSIONS_ENUM.WRITE,
  clone: PERMISSIONS_ENUM.WRITE,
  calender: PERMISSIONS_ENUM.WRITE,
  copy: PERMISSIONS_ENUM.WRITE,
  changePlan: PERMISSIONS_ENUM.READ,
  showAgreement: PERMISSIONS_ENUM.WRITE,
  refresh: PERMISSIONS_ENUM.WRITE,
  resendVerificationRevamp: PERMISSIONS_ENUM.WRITE,
  apply: PERMISSIONS_ENUM.WRITE,
  end: PERMISSIONS_ENUM.WRITE,
  validate: PERMISSIONS_ENUM.WRITE,
  reonboard: PERMISSIONS_ENUM.WRITE,
  onboard: PERMISSIONS_ENUM.WRITE,
  configure: PERMISSIONS_ENUM.WRITE,
  stop: PERMISSIONS_ENUM.WRITE,
  download: PERMISSIONS_ENUM.READ,
};

export const NO_ACCESS_MESSAGE = "You don't have the access rights to view this content.";
