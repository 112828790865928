import React from "react";
import { numberWithCommasandDecimal } from "../../../../../../../Utils/commonUtils";

export default function UnusedCard(props) {
  const { data, className } = props;

  return (
    <div className={`flex flex-col rounded-[5px] bg-[#FFFFFF] p-[10px] items-start ${className}`}>
      <span className="mb-[9px] px-[2px] py-[3px] rounded-[50%] bg-[#E5F3FF]">{data?.icon}</span>
      <div className="text-[12px] font-semibold text-[#0C477E]">
        <span>{data?.title}</span>{" "}
        <span className="text-[11px] text-[#919191] italic">{data?.subtitle}</span>
      </div>
      <div className="text-[15px] text-[#000000] mt-[6px]">
        {numberWithCommasandDecimal(data?.value)}
      </div>
    </div>
  );
}
