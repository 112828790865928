import isEqual from "lodash.isequal";

export function hasDashboardIds(dashboards, ids) {
  return ids.every((id) => dashboards.some((obj) => obj.id === id));
}

export const isSaveReport = (dashboards) => {
  const isIdPresent = ["COST_ANALYSIS", "COST_EXPLORER_SAVED_REPORTS"];
  return hasDashboardIds(dashboards, isIdPresent);
};

export function decodeAndParseQueryString(queryStringURL) {
  let queryString;
  if (queryStringURL.startsWith("?")) {
    queryString = queryStringURL?.substring(1);
  }
  const params = new URLSearchParams(queryString);
  const result = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of params.entries()) {
    try {
      if (key === "Filters") {
        const intermediateDecode = decodeURIComponent(value);
        const filtersArray = JSON.parse(intermediateDecode);
        const parsedFilters = filtersArray?.map((filter) => JSON.parse(filter));
        result[key] = parsedFilters;
      } else {
        result[key] = decodeURIComponent(value);
      }
    } catch (e) {
      console.error(`Error parsing key ${key}:`, e);
      result[key] = decodeURIComponent(value);
    }
  }

  return result;
}
export const checkEquality = (oldURL, newURL) => {
  const oldObject = decodeAndParseQueryString(oldURL);
  const newObject = decodeAndParseQueryString(newURL);
  return !isEqual(oldObject, newObject);
};
