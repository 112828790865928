import React from "react";
import { Checkbox, Switch, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import moment from "moment";
import { Link } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as DeleteIcon } from "../../../Assets/icons/deleteIcon.svg";
import { ReactComponent as TickIcon } from "../../../Assets/icons/blue-tick-only.svg";
import { ReactComponent as CartAddedIcon } from "../../../Assets/icons/tick-only.svg";
import { ReactComponent as CartAdded } from "../../../Assets/icons/cartAddedIcon.svg";
import Button from "../../FormConfig/Form/Components/Button";
import { ReactComponent as EditIcon } from "../../../Assets/icons/editIcon.svg";
import { ReactComponent as EditIconNew } from "../../../Assets/icons/others/new-edit-icon.svg";
import { ReactComponent as PromoteIconRevamp } from "../../../Assets/icons/others/promote-icon.svg";
import { ReactComponent as ResentVerficationIconRevamp } from "../../../Assets/icons/others/resend-verfication.svg";
import { ReactComponent as CancelRowIcon } from "../../../Assets/icons/cancel-table-row.svg";
import { ReactComponent as SaveRowIcon } from "../../../Assets/icons/blue-tick.svg";
import { ReactComponent as AddRowIcon } from "../../../Assets/icons/add-row.svg";
import { ReactComponent as PromoteIcon } from "../../../Assets/icons/promote-icon.svg";
import { ReactComponent as CloneIcon } from "../../../Assets/icons/clone-icon.svg";
import { ReactComponent as EyeActiveIcon } from "../../../Assets/icons/eye-active.svg";
import { ReactComponent as AddToCartIcon } from "../../../Assets/icons/addToCart.svg";
import { ReactComponent as SortIcon } from "../../../Assets/icons/sorting_v2.svg";
import { ReactComponent as PlusIconBox } from "../../../Assets/icons/ic-plus-box.svg";
import { ReactComponent as MinusIconBox } from "../../../Assets/icons/ic-minus-box.svg";
import { ReactComponent as DownloadArrowIcon } from "../../../Assets/icons/others/download.svg";
import calendarIcon from "../../../Assets/icons/ic-date.svg";

import { dateConfiguration } from "../../FormConfig";
import Calendar from "../../FormConfig/Form/Components/Calendar/Calender";
import { noop } from "../../../Utils/commonUtils";
import DateTime from "../../FormConfig/Form/Components/DateTimeCalender/Calender";
import TableHeaderTooltip from "./components/tableHeaderTooltip";
import TooltipV2 from "../TooltipV2";

export const returnToggleCase = (icon, hasWritePermission, tooltipTop, tableBody, onIconClick) => (
  <span
    aria-hidden
    key={icon.actionType}
    className={`${icon.class} ${hasWritePermission} ${
      icon.props.defaultChecked ? "toggleEnable" : "toggleDisable"
    }`}
  >
    <Tooltip
      TransitionProps={{ timeout: 200 }}
      PopperProps={{ sx: tooltipTop }}
      title={icon.props.defaultChecked ? "Disable" : "Enable"}
      arrow
      placement="top"
    >
      <Switch
        {...icon.props}
        checked={icon.props.defaultChecked}
        disabled={icon.disabled}
        onChange={(event, value) => onIconClick(icon.actionType, tableBody, value)}
      />
    </Tooltip>
  </span>
);
export const returnDeleteCase = (icon, tooltipTop, tableBody, onIconClick, index) => (
  <span
    aria-hidden
    key={icon.actionType}
    className={`${icon?.class} ${icon?.disabled ? "disable" : ""}`}
  >
    <Tooltip
      TransitionProps={{ timeout: 200 }}
      PopperProps={{ sx: tooltipTop }}
      title="Delete"
      arrow
      placement="top"
    >
      <div
        onClick={() => {
          onIconClick(icon.actionType, tableBody, index);
        }}
        role="button"
        tabIndex="0"
        className="flex"
        onKeyPress={(event) => {
          if (event.key === "Enter" || event.key === " ") {
            onIconClick(icon.actionType, tableBody, index);
          }
        }}
      >
        <DeleteIcon height="12px" width="12px" />
        {icon.showDeleteText && <span>Delete</span>}
      </div>
    </Tooltip>
  </span>
);

export const returnAgreementCase = (icon, tooltipTop, tableBody, onIconClick) => (
  <span
    aria-hidden
    key={icon.actionType}
    className={`${icon?.class} ${icon?.disabled ? "disable" : ""} flex`}
  >
    <Tooltip
      TransitionProps={{ timeout: 200 }}
      PopperProps={{ sx: tooltipTop }}
      title="Agree"
      arrow
      placement="top"
    >
      <span aria-hidden className="!ml-[8px] text-[#0A3CA2] divider_left">
        <button
          type="button"
          className="button outline-primary medium !ml-[7px] rounded !h-[25px] cursor-pointer !px-[5px]"
          onClick={() => onIconClick(icon.actionType, tableBody)}
        >
          <TickIcon className="!w-[12px] !h-[12px] divider" /> Agree
        </button>
      </span>
    </Tooltip>
  </span>
);

export const returnChangePlanCase = (icon, tooltipTop, tableBody) => (
  <span
    aria-hidden
    key={icon.actionType}
    className={`${icon?.class} ${icon?.disabled ? "disable" : ""} text-[#0A3CA2]`}
  >
    <Tooltip
      TransitionProps={{ timeout: 200 }}
      PopperProps={{ sx: tooltipTop }}
      title="Change Plan"
      arrow
      placement="top"
    >
      <span aria-hidden className="cursor-pointer">
        <Link
          className="button primary medium switch-button rounded !h-[25px]"
          to={`/dashboard/plans-selection/customer/${tableBody.name}/${tableBody.id}`}
        >
          Change Plan
        </Link>
      </span>
    </Tooltip>
  </span>
);
export const returnAddCartCase = (icon, tableBody, onInputChange, onAddCart) => {
  let cartText;
  let cartIcon;

  if (tableBody.cartAdded) {
    cartText = icon.showAddedCart ? "Item Added" : "Added";
    cartIcon = icon.showAddedCart ? <CartAdded /> : <CartAddedIcon className="" />;
  } else {
    cartText = "Add to Cart";
    cartIcon = <AddToCartIcon />;
  }
  return (
    <div className="flex items-center !gap-[8px]">
      <span aria-hidden key={icon.actionType} className={`${icon.class}`}>
        <Button
          className={`outline-primary whitespace-nowrap rounded !font-medium text-[11px] !px-[8px] !min-h-[18px] !min-w-[103px] ${
            !tableBody.quantity ? "disabled" : ""
          } ${
            tableBody.cartAdded && tableBody.quantity
              ? "!border-[#166c08] !text-[#166c08] hover:shadow-[0px_6px_5px_rgba(100,172,86,0.101)]"
              : ""
          }`}
          size="small"
          // variant="primary"
          imageIcon={cartIcon}
          id="resend_verification"
          text={cartText}
          type="submit"
          handleClick={() => {
            onAddCart(icon.actionType, tableBody);
          }}
          disabled={!tableBody.quantity}
        />
      </span>
    </div>
  );
};

export const returnEditCase = (icon, tooltipTop, tableBody, index, onIconClick) => (
  <span aria-hidden key={icon.actionType} className={`${icon.class}`}>
    <Tooltip
      TransitionProps={{ timeout: 200 }}
      PopperProps={{ sx: tooltipTop }}
      title="Edit"
      arrow
      placement="top"
    >
      <EditIcon
        onClick={() => {
          onIconClick(icon.actionType, tableBody, {}, index);
        }}
      />
    </Tooltip>
  </span>
);

export const returnEditRevampCase = (icon, tooltipTop, tableBody, index, onIconClick) => (
  <span aria-hidden key={icon.actionType} className={`${icon.class}`}>
    <Tooltip
      TransitionProps={{ timeout: 200 }}
      PopperProps={{ sx: tooltipTop }}
      title="Edit"
      arrow
      placement="top"
    >
      <EditIconNew
        onClick={() => {
          onIconClick(icon.actionType, tableBody, {}, index);
        }}
      />
    </Tooltip>
  </span>
);

export const returnCancelCase = (icon, tooltipTop, tableBody, onIconClick) => (
  <span aria-hidden key={icon.actionType} className={`${icon.class}`}>
    <Tooltip
      TransitionProps={{ timeout: 200 }}
      PopperProps={{ sx: tooltipTop }}
      title="Cancel"
      arrow
      placement="top"
    >
      <CancelRowIcon
        // {...icon.props}
        color="primary"
        onClick={() => {
          onIconClick(icon.actionType, tableBody);
        }}
      />
    </Tooltip>
  </span>
);

export const returnSaveCase = (icon, tooltipTop, tableBody, onIconClick) => (
  <span aria-hidden key={icon.actionType} className={`${icon.class}`}>
    <Tooltip
      TransitionProps={{ timeout: 200 }}
      PopperProps={{ sx: tooltipTop }}
      title="Save"
      arrow
      placement="top"
    >
      <SaveRowIcon
        // {...icon.props}
        onClick={() => {
          onIconClick(icon.actionType, tableBody);
        }}
      />
    </Tooltip>
  </span>
);
export const returnAddCase = (icon, tooltipTop, tableBody, onIconClick) => (
  <span aria-hidden key={icon.actionType} className={`${icon.class}`}>
    <Tooltip
      TransitionProps={{ timeout: 200 }}
      PopperProps={{ sx: tooltipTop }}
      title="Add"
      arrow
      placement="top"
    >
      <AddRowIcon
        // {...icon.props}
        onClick={() => {
          onIconClick(icon.actionType, tableBody);
        }}
      />
    </Tooltip>
  </span>
);
export const returnEndCase = (icon, tooltipTop, tableBody, onIconClick) => (
  <span aria-hidden key={icon.actionType} className={`${icon.class}`}>
    <Tooltip
      TransitionProps={{ timeout: 200 }}
      PopperProps={{ sx: tooltipTop }}
      title="End"
      arrow
      placement="top"
    >
      <span
        onClick={() => {
          onIconClick(icon.actionType, tableBody);
        }}
        aria-hidden
      >
        End
      </span>
    </Tooltip>
  </span>
);

const isDisabled = (tableBody, icon) => icon.disabled || !tableBody.enable;

export const returnPromoteRevampCase = (icon, tableBody, onIconClick, tooltipTop) => (
  // const userData = getLocalStorageUserData();
  // if (userData?.currentUser?.userRoleType?.toUpperCase() !== "OWNER") {
  //   return null;
  // }
  <span
    aria-hidden
    key={icon.actionType}
    className={`${icon.class} ${
      isDisabled(tableBody, icon) ? "disabled pointer-events-none" : ""
    } `}
  >
    <Tooltip
      TransitionProps={{ timeout: 200 }}
      PopperProps={{ sx: tooltipTop }}
      title="Promote User"
      arrow
      placement="top"
    >
      <PromoteIconRevamp
        onClick={() =>
          isDisabled(tableBody, icon) ? noop() : onIconClick(icon.actionType, tableBody)
        }
      />
    </Tooltip>
  </span>
);

export const returnPromoteCase = (icon, tableBody, onIconClick) => (
  // const userData = getLocalStorageUserData();
  // if (userData?.currentUser?.userRoleType?.toUpperCase() !== "OWNER") {
  //   return null;
  // }
  <span aria-hidden key={icon.actionType} className={`${icon.class}`}>
    <Button
      className="rounded !font-medium text-[11px] !px-[8px] !h-[23px] w-[71px]"
      size="small"
      variant="primary"
      id="promote"
      disabled={isDisabled(tableBody, icon)}
      text="Promote"
      type="submit"
      handleClick={() => {
        onIconClick(icon.actionType, tableBody);
      }}
      imageIconinLast={<PromoteIcon />}
    />
  </span>
);

export const returnValidateCase = (icon, tableBody, onIconClick) => (
  // const userData = getLocalStorageUserData();
  // if (userData?.currentUser?.userRoleType?.toUpperCase() !== "OWNER") {
  //   return null;
  // }
  <span aria-hidden key={icon.actionType} className={`${icon.class}`}>
    <button
      className="rounded !font-medium text-[11px] !px-[8px] !h-[23px] w-[71px] v3 outlined v3-link"
      size="small"
      id="validate"
      type="submit"
      onClick={() => {
        onIconClick(icon.actionType, tableBody);
      }}
      disabled={tableBody?.isVerified}
    >
      {/* <ValidateIcon /> */}
      Validate
    </button>
  </span>
);

export const returnReOnboardCase = (icon, tableBody, onIconClick) => (
  <span aria-hidden key={icon.actionType} className={`${icon.class}`}>
    <button
      className="rounded !font-medium text-[11px] !px-[8px] !h-[23px] w-[71px] v3 outlined"
      size="small"
      id="validate"
      type="submit"
      onClick={() => {
        onIconClick(icon.actionType, tableBody);
      }}
    >
      {" "}
      Re-Onboard
    </button>
  </span>
);

export const returnOnboardCase = (icon, tableBody, onIconClick) => (
  // const userData = getLocalStorageUserData();
  // if (userData?.currentUser?.userRoleType?.toUpperCase() !== "OWNER") {
  //   return null;
  // }
  <span aria-hidden key={icon.actionType} className={`${icon.class}`}>
    <button
      className="rounded !font-medium text-[11px] !px-[8px] !h-[23px] w-[71px] v3 outlined v3-link"
      size="small"
      id="validate"
      type="submit"
      onClick={() => {
        onIconClick(icon.actionType, tableBody);
      }}
    >
      {" "}
      Onboard Account
    </button>
  </span>
);

export const returnConfigureCase = (icon, tableBody, onIconClick, disabled) => (
  <span aria-hidden key={icon.actionType} className={`${icon.class}`}>
    <button
      className="text-[11px] text-[#0A3CA2] font-medium disabled:cursor-not-allowed"
      size="small"
      id="configure"
      type="submit"
      onClick={() => {
        onIconClick(icon.actionType, tableBody);
      }}
      disabled={disabled}
    >
      {" "}
      Configure
    </button>
  </span>
);

export const returnStopCase = (icon, tableBody, onIconClick, text) => (
  <span aria-hidden key={icon.actionType} className={`${icon.class}`}>
    <button
      disabled={icon.disabled}
      className="v3 outlined !p-[4px]"
      size="small"
      id="stop"
      type="submit"
      onClick={() => {
        onIconClick(icon.actionType, tableBody);
      }}
    >
      {" "}
      {text}
    </button>
  </span>
);

export const returnRefreshCase = (tableBody, icon, onIconClick) => (
  <span aria-hidden key={icon?.actionType} className={`${icon?.class}`}>
    <Button
      className="rounded !font-medium text-[11px] !px-[8px] !h-[23px] w-[85px]"
      size="small"
      variant="primary"
      id="Refresh"
      disabled={icon?.disabled}
      text={icon?.text ?? "Refresh"}
      type="submit"
      handleClick={() => {
        onIconClick(icon?.actionType, tableBody);
      }}
    />
  </span>
);

export const returnResendVerificationRevampCase = (tableBody, icon, onIconClick, tooltipTop) =>
  !tableBody.emailVerified &&
  tableBody.enable && (
    <span aria-hidden key={icon.actionType} className={`${icon.class}`}>
      <Tooltip
        TransitionProps={{ timeout: 200 }}
        PopperProps={{ sx: tooltipTop }}
        title="Resend Link"
        arrow
        placement="top"
      >
        <ResentVerficationIconRevamp
          onClick={() => {
            onIconClick(icon.actionType, tableBody);
          }}
        />
      </Tooltip>
    </span>
  );

export const returnResendVerification = (tableBody, icon, onIconClick) =>
  !tableBody.emailVerified &&
  tableBody.enable && (
    <span aria-hidden key={icon.actionType} className={`${icon.class}`}>
      <Button
        className="rounded !font-medium text-[11px] !px-[8px] !h-[23px] w-[85px]"
        size="small"
        variant="outline-primary"
        id="resend_verification"
        // disabled={tableBody.emailVerified}
        text="Resend Link"
        type="submit"
        handleClick={() => {
          onIconClick(icon.actionType, tableBody);
        }}
        // imageIconinLast={<PromoteIcon />}
      />
    </span>
  );

export const returnInputCase = (
  onInputChange,
  tableBody,
  min = Number.NEGATIVE_INFINITY,
  allowNumberOnly = false,
  icon = {}
) => (
  <div
    className={`flex items-center justify-center !w-[100%] border-[#DDDFE8] ${icon.class ?? ""}`}
  >
    {!icon.defaultInput && (
      <span
        aria-hidden="true"
        onClick={() => {
          onInputChange(
            { target: { name: "quantity", value: Number(tableBody.quantity - 1) } },
            tableBody
          );
        }}
        className={`!w-[18px] !h-[30px] flex items-center justify-center rounded-l-[4px] border-[1px] border-[#DDDFE8] border-r-0 border-solid bg-[#F7FAFF] cursor-pointer ${
          tableBody.quantity < min
            ? "!border-y-[#EB3916] !border-l-[#EB3916] pointer-events-none"
            : ""
        }`}
      >
        <RemoveIcon className="!h-[15px] minus_icon !fill-[#0A3CA2]" />
      </span>
    )}
    <input
      onChange={(e) => {
        const newValue = Number(e.target.value);
        if (
          (allowNumberOnly && Number.isInteger(newValue)) ||
          (!allowNumberOnly && newValue >= 0 && newValue <= 999)
        ) {
          onInputChange(
            { target: { name: "quantity", value: newValue }, customEvent: e },
            tableBody
          );
        }
      }}
      // onChange={(e) => e.preventDefault()}
      value={tableBody.quantity}
      className={`text-center !px-[8px] !h-[30px] !rounded-none !w-[40px] !mb-[0px] ${
        tableBody.quantity < min
          ? `!text-[#EB3916] !border-y-[#EB3916] ${
              icon.defaultInput && "!border-x-[#EB3916] !border-l-[#EB3916]"
            }`
          : ""
      }}`}
      name="quantity"
      type="number"
      id={tableBody?.id}
    />
    {!icon.defaultInput && (
      <span
        aria-hidden="true"
        onClick={() => {
          onInputChange(
            { target: { name: "quantity", value: Number(tableBody.quantity + 1) } },
            tableBody
          );
        }}
        className={`!w-[18px] !h-[30px] flex items-center justify-center rounded-r-[4px] border-[1px] border-[#DDDFE8] border-l-0 border-solid bg-[#F7FAFF] cursor-pointer ${
          tableBody.quantity < 1 ? "!border-y-[#EB3916] !border-r-[#EB3916]" : ""
        }`}
      >
        <AddIcon className="!h-[15px] plus_icon !fill-[#0A3CA2]" />
      </span>
    )}
  </div>
);

export const returnCloneCase = (tableBody, tooltipTop, icon, onIconClick) => (
  <span aria-hidden key={icon.actionType} className={`${icon.class}`}>
    <Tooltip
      TransitionProps={{ timeout: 200 }}
      PopperProps={{ sx: tooltipTop }}
      title="Clone"
      arrow
      placement="top"
    >
      <CloneIcon
        onClick={() => {
          onIconClick(icon.actionType, tableBody);
        }}
      />
    </Tooltip>
  </span>
);

export const returnPreviewCase = (tableBody, tooltipTop, icon, onIconClick, iconText) => (
  <span aria-hidden key={icon.actionType} className={`${icon.class}`}>
    <Tooltip
      TransitionProps={{ timeout: 200 }}
      PopperProps={{ sx: tooltipTop }}
      title={iconText || "Preview"}
      arrow
      placement="top"
    >
      <EyeActiveIcon
        onClick={() => {
          onIconClick(icon.actionType, tableBody);
        }}
      />
    </Tooltip>
  </span>
);

export const returnCopyCase = (tableBody, tooltipTop, onIconClick, icon) => (
  <span aria-hidden key={icon.actionType} className={`${icon.class}`}>
    <Tooltip
      TransitionProps={{ timeout: 200 }}
      PopperProps={{ sx: tooltipTop }}
      title="Copy"
      arrow
      placement="top"
    >
      <CloneIcon
        onClick={() => {
          onIconClick(icon.actionType, tableBody);
        }}
      />
    </Tooltip>
  </span>
);

export const returnCalenderCase = (tableBody, iconChange, icon = {}, minDate = null) => (
  <div className={`w-[150px] ${icon?.class}`}>
    <Calendar
      key={dateConfiguration.name}
      {...dateConfiguration}
      handleChange={(event) => iconChange(event.target.value, tableBody)}
      value={moment(tableBody.orderOn).format("DD-MMM-YYYY")}
      minDate={minDate}
      icon={calendarIcon}
      autoApply
    />
  </div>
);

export const returnDateTimeCase = (
  tableBody,
  iconChange,
  icon = {},
  minDate = null,
  maxDate = moment("2099-12-31").format("MM-DD-YYYY HH:mm:ss")
) => (
  <div className={`w-[200px] ${icon?.class}`}>
    <DateTime
      key={dateConfiguration.name}
      {...dateConfiguration}
      handleChange={(event) => iconChange(event.target.value, tableBody)}
      value={
        tableBody.orderOn
          ? moment(tableBody.orderOn).format("DD-MMM-YYYY HH:mm:ss")
          : moment().startOf("day").format("DD-MMM-YYYY HH:mm:ss")
      }
      minDate={minDate}
      maxDate={maxDate}
      icon={calendarIcon}
      autoApply
    />
  </div>
);

export function getSortIcon(selectedSortHeader, ascending) {
  let returnClass = "";
  if (selectedSortHeader) {
    returnClass = !ascending ? "up_icon" : "down_icon";
  }
  return <SortIcon className={`sorting_v2 ${returnClass}`} />;
}

export const returnApplyCase = (tableBody, icon, onIconClick, tooltipTop) => (
  <span aria-hidden key={icon.actionType} className={`${icon.class}`}>
    <Tooltip
      TransitionProps={{ timeout: 200 }}
      PopperProps={{ sx: tooltipTop }}
      title="Apply"
      arrow
      placement="top"
    >
      <span
        aria-hidden
        onClick={() => {
          onIconClick(icon.actionType, tableBody);
        }}
      >
        Apply
      </span>
    </Tooltip>
  </span>
);

export const returnDownloadCase = (icon, tableBody, onIconClick, tooltipTop, iconText) => (
  <span aria-hidden key={icon?.actionType} className={`${icon?.class}`}>
    <Tooltip
      TransitionProps={{ timeout: 200 }}
      PopperProps={{ sx: tooltipTop }}
      title={iconText || "Download"}
      arrow
      placement="top"
    >
      <DownloadArrowIcon
        onClick={() => {
          onIconClick(icon?.actionType, tableBody);
        }}
      />
    </Tooltip>
  </span>
);

export const getClassName = (tableBody, disableFunctionality, tableName = "") => {
  let className = "table-body-row";

  if (tableBody.status === "Nullified") {
    className += " disabled-row-v2";
  }

  if (tableBody.rowClass) {
    className += ` ${tableBody.rowClass}`;
  }

  if (tableBody.expanded) {
    className += " expanded";
  }
  if (tableName !== "billdesk_credits" && tableBody.status === "Expired") {
    className += " disabled-row";
  }

  if (tableName === "billdesk_credits" && tableBody.status === "Expired") {
    className += " grey-out-row";
  }

  if (
    disableFunctionality &&
    tableName !== "billdesk_credits" &&
    (tableBody.state === "Expired" ||
      tableBody.status === "Expired" ||
      tableBody.status === "Unassociated")
  ) {
    className += " disabled-row";
  }

  return className;
};

export const returnInputArrowCase = (
  onInputChange,
  tableBody,
  min = Number.NEGATIVE_INFINITY,
  allowNumberOnly = false,
  icon = {}
) => (
  <div
    className={`flex items-center justify-center !w-[100%] border-[#DDDFE8] relative ${
      icon.class ?? ""
    }`}
  >
    <input
      onChange={(e) => {
        const newValue = Number(e.target.value);
        if (
          (allowNumberOnly && Number.isInteger(newValue)) ||
          (!allowNumberOnly && newValue >= 0 && newValue <= 999)
        ) {
          onInputChange(
            { target: { name: "quantity", value: newValue }, customEvent: e },
            tableBody
          );
        }
      }}
      // onChange={(e) => e.preventDefault()}
      value={tableBody.quantity}
      className={`text-left !px-[8px] !h-[30px] !w-[60px] !mb-[0px] ${
        tableBody.quantity < min ? "!text-[#EB3916] !border-[#EB3916] rounded" : ""
      }}`}
      name="quantity"
      type="number"
      id={tableBody?.id}
    />
    <div className="flex flex-col absolute left-[40px] cursor-pointer">
      {!icon.defaultInput && (
        <span
          aria-hidden="true"
          onClick={() => {
            onInputChange(
              { target: { name: "quantity", value: Number(tableBody.quantity + 1) } },
              tableBody
            );
          }}
          className={`${tableBody.quantity < 1 ? "!border-y-[#EB3916] !border-r-[#EB3916]" : ""}`}
        >
          <KeyboardArrowUpIcon className="!h-[15px] up_icon !fill-[#0A3CA2]" />
        </span>
      )}
      {!icon.defaultInput && (
        <span
          aria-hidden="true"
          onClick={() => {
            onInputChange(
              { target: { name: "quantity", value: Number(tableBody.quantity - 1) } },
              tableBody
            );
          }}
          className={`${
            tableBody.quantity < min
              ? "!border-y-[#EB3916] !border-l-[#EB3916] pointer-events-none"
              : ""
          }`}
        >
          <KeyboardArrowDownIcon className="!h-[15px] down_icon !fill-[#0A3CA2]" />
        </span>
      )}
    </div>
  </div>
);

export const groupNameHeader = (itemLabel = "", handleChange = noop, isExpanded = false) => (
  <div className="flex gap-[5px] items-center">
    <button
      type="button"
      className="cursor-pointer"
      onClick={(e) => handleChange(e, "toggleAll", !isExpanded)}
    >
      {isExpanded ? <MinusIconBox /> : <PlusIconBox />}
    </button>
    <span>{itemLabel}</span>
  </div>
);

export const handleItemAccessor = (
  { item, data, currentParent, singleLevelSelectAll, isExpanded = false },
  { checkChecked, calcCheck, handleAll, handleChange, renderSortFilter, checkIndeterminate }
) => {
  if (item?.accessor === "checkboxId" && item?.isSelectAll === true) {
    return (
      <div className="text-field flex items-center select-all-checkbox">
        <Checkbox
          name="selectAll"
          className={singleLevelSelectAll ? "table_checkbox" : ""}
          checked={singleLevelSelectAll ? checkChecked() : calcCheck()}
          indeterminate={singleLevelSelectAll ? checkIndeterminate() : false}
          disabled={
            Object.keys(currentParent)?.length
              ? currentParent?.childAccounts?.every(
                  (child) => child?.endDate || child?.status !== "Active"
                )
              : data.every((parent) =>
                  parent.childAccounts?.every(
                    (child) =>
                      child?.status !== "Active" || (child?.status === "Active" && child?.endDate)
                  )
                )
          }
          size="small"
          onChange={(event) => {
            if (singleLevelSelectAll) {
              handleAll(event);
            } else {
              handleChange(
                event,
                "selectAll",
                currentParent?.childAccounts || data,
                currentParent?.id,
                true,
                true
              );
            }
          }}
        />
      </div>
    );
  }

  if (item?.accessor === "includeAllExpanded") {
    return (
      <div className="text-field flex items-center select-all-checkbox">
        {groupNameHeader(item?.Header, handleAll, isExpanded)}
      </div>
    );
  }

  if (item?.tooltipContent) {
    return (
      <TooltipV2
        tooltipid="table_reset_stylings"
        parentClass="table_header_tooltip"
        popoverId="ck-tuner-popover"
        displayText={() => "Status"}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <TableHeaderTooltip>{item.tooltipContent()}</TableHeaderTooltip>
      </TooltipV2>
    );
  }

  return (
    <>
      {item?.subLegend && (
        <div className={`sub-legends-wrapper ${item?.childClass}`}>
          <div className="sub-legends-content-wrapper">
            <span>{item?.subLegend}</span>
            <div className="horizontal-line" />
          </div>
        </div>
      )}
      {renderSortFilter(item)}
    </>
  );
};
